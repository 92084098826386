// React & Next
import { createElement } from 'react';

// 3rd
import { Flex } from '@chakra-ui/react';

// App - Types
import { Position } from '@/components/molecules/charts/flow';
import type { NodeProps } from '@/components/molecules/charts/flow';
import type { StoriesGraphVertexData } from '../types';

// App - Other
import { DocumentIcon } from '@/components/atoms/icon';
import { EllipsisText } from '@/components/atoms/typography';
import { IconBox } from '@/components/molecules/icon-box';
import { Handle } from './handle';

type LinkedKnowledgeItemVertexProps = NodeProps<StoriesGraphVertexData>;

export const LinkedKnowledgeItemVertex = ({
  data: { title, icon, sx },
}: LinkedKnowledgeItemVertexProps) => {
  return (
    <Flex direction="column" position="relative" maxW={`${VERTEX_MAX_WIDTH}px`}>
      <Handle type="target" position={Position.Top} />

      <Handle type="source" position={Position.Bottom} />

      <Handle type="target" position={Position.Left} />

      <Handle type="source" position={Position.Right} />

      <Flex
        alignItems="center"
        gap="sm"
        border="1px"
        borderColor="border.brand.primary"
        borderRadius="md"
        py="xxs"
        px="sm"
        boxShadow="elevation-5"
        sx={sx}
      >
        <Flex alignItems="center" gap="sm" bg="surface.primary" py="sm" px="md" borderRadius="sm">
          <IconBox bg="transparent" w="28px" h="28px" minW="28px" minH="28px" borderRadius="md">
            {createElement(icon, {
              size: 'xs',
              'aria-label': 'KnowledgeItem Source',
            })}
          </IconBox>

          <EllipsisText maxW={`${VERTEX_MAX_WIDTH - 110}px`} variant="detail">
            {title}
          </EllipsisText>
        </Flex>

        <DocumentIcon aria-label="KnowledgeItem Type" color="text.invert-primary" size="sm" />
      </Flex>
    </Flex>
  );
};

export const VERTEX_MAX_WIDTH = 250;
export const EXTENSION_NAME = 'LinkedKnowledgeItemVertex';
export const EXTENSION = {
  LinkedKnowledgeItemVertex: LinkedKnowledgeItemVertex,
};
