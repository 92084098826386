// 3rd
import { Skeleton, forwardRef } from '@chakra-ui/react';
import type { ComponentWithAs, As } from '@chakra-ui/react';

// App - Types
import type { CoveredRequirement } from '../../types/requirements-analysis';

// App - Other
import { Text } from '@/components/atoms/typography';
import { Insight } from '@/components/molecules/insight';

type StoriesRequirementViolationInsightComponent = ComponentWithAs<
  As,
  StoriesCoveredRequirementInsightProps
> & { Loading: typeof Loading };

type StoriesCoveredRequirementInsightProps = {
  coveredRequirement: CoveredRequirement;
  isPinned: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export const StoriesCoveredRequirementInsight = forwardRef(
  (
    {
      coveredRequirement,
      isPinned,
      onClick,
      onMouseEnter,
      onMouseLeave,
    }: StoriesCoveredRequirementInsightProps,
    ref
  ) => {
    return (
      <Insight
        title={
          <Text
            variant="detail"
            display="flex"
            alignItems="center"
            flex="1 0 0"
            py="xxs"
            px="none"
            color="text.secondary"
          >
            ({coveredRequirement.name}) {coveredRequirement.requirement}
          </Text>
        }
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
        styleOptions={{
          container: {
            border: '1px',
            borderColor: 'border.primary',
            borderRadius: 'sm',
            cursor: 'pointer',
          },

          header: {
            transition: 'all 150ms ease-in-out',
            bg: isPinned ? 'surface.secondary' : 'transparent',
            py: 'xs',

            _hover: {
              bg: 'surface.secondary',
            },
          },

          lineDecoration: {
            my: '2px',
            bg: 'surface.low-tone',
          },
        }}
      />
    );
  }
) as StoriesRequirementViolationInsightComponent;

const Loading = () => {
  return <Skeleton h="42px" w="100%" />;
};

StoriesCoveredRequirementInsight.Loading = Loading;
