// 3rd
import { Box, Flex } from '@chakra-ui/react';
import { Text } from '@/components/atoms/typography';
import {
  translateLikelihoodScore,
  translateLikelihoodScoreToColor,
} from '@/components/translators/story/likelihood';

type LikelihoodBarProps = {
  likelihoodScore: number;
};

export const LikelihoodBar = ({ likelihoodScore }: LikelihoodBarProps) => {
  const isActive = (level: number) => level <= likelihoodScore;

  return (
    <Flex w="100%" h="100%">
      <Flex
        gap="2px"
        h="100%"
        w="100%"
        position="relative"
        _before={{
          content: '""',
          borderRadius: '2px',
          bg: '#F5F7F9',
          w: '100%',
          h: '10px',
          position: 'absolute',
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <Flex
            key={`likelihood-score-bar-${index + 1}`}
            direction="column"
            gap="4px"
            w={`calc(${100 / 5}% - 2px)`}
            zIndex={2}
          >
            <Box
              ms="2px"
              mt="2px"
              minW="20px"
              w="100%"
              h="8px"
              bg={
                isActive(index + 1) ? translateLikelihoodScoreToColor(likelihoodScore) : '#F5F7F9'
              }
              borderRadius="2px"
            />

            <Text
              variant="small"
              color={likelihoodScore === index + 1 ? 'text.primary' : 'text.mid-tone'}
              alignSelf="center"
            >
              {translateLikelihoodScore(index + 1)}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
