// 3rd
import { z } from 'zod';

// App - Types
import { castThreatSeverityScoreDtoToThreatSeverity } from '@/types/story/dtos/threat-severity-score';
import type { Threat } from '../../types/threats-analysis';

export const ZodThreatDto = z.object({
  capec: z.string(),
  category: z.string(),
  confidence: z.number(),
  countermeasures: z.string(),
  createdAt: z.string(),
  cwes: z.string().array(),
  generalBackground: z.string(),
  isRelevant: z.boolean(),
  likelihood: z.number(),
  reasoning: z.string(),
  severity: z.number(),
  threat: z.string(),
});

export type ThreatDto = z.infer<typeof ZodThreatDto>;

export const castDtoToThreat = (dto: ThreatDto): Threat => {
  return {
    category: dto.category,
    threat: dto.threat,
    reasoning: dto.reasoning,
    generalBackground: dto.generalBackground,
    countermeasures: dto.countermeasures,
    likelihoodScore: dto.likelihood,
    severityScore: dto.severity,
    severity: castThreatSeverityScoreDtoToThreatSeverity(dto.severity),
  };
};
