// 3rd
import { z } from 'zod';

// App - Types
import type { AddressedSecurityConsideration } from '../../../types/security-analysis';

export const ZodAddressedSecurityConsiderationDto = z.object({
  consideration: z.string(),
  reference: z.string(),
  threatMitigated: z.string(),
});

export type AddressedSecurityConsiderationDto = z.infer<
  typeof ZodAddressedSecurityConsiderationDto
>;

export const castDtoToAddressedSecurityConsideration = (
  dto: AddressedSecurityConsiderationDto
): AddressedSecurityConsideration => {
  return {
    consideration: dto.consideration,
    reasoning: dto.reference,
    threatMitigated: dto.threatMitigated,
  };
};
