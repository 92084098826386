// 3rd
import { z } from 'zod';

// App - Types
import type { SlackFeedbackRecipient } from '../../types/slack-feedback-recipient';

export const ZodSlackRecipientDto = z.object({
  email: z.string().optional(),
  integrationConfigurationId: z.string(),
  // integrationType: z.string(),
  integrationUserId: z.string(),
  name: z.string(),
  // realName: z.string().optional(),
});

export type SlackRecipientDto = z.infer<typeof ZodSlackRecipientDto>;

export const castSlackRecipientDtoToStory = (dto: SlackRecipientDto): SlackFeedbackRecipient => {
  return {
    id: dto.integrationUserId,
    workspaceId: dto.integrationConfigurationId,
    email: dto.email,
    name: dto.name,
  };
};
