// 3rd
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { z, ZodError } from 'zod';

// App - Types
import type { Story } from '../types/story';
import { ZodStoryDto, castDtoToStory } from './dtos/story';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityStories: z.array(ZodStoryDto).default([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getStories = async (): Promise<Story[]> => {
  try {
    const res = await apiClient.get(`/SecurityStory/ListSecurityStories`);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityStories.map(castDtoToStory);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    if (e instanceof ZodError) {
      console.error(e, e.issues);
    } else {
      console.error(e);
    }

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseStories = {
  enabled?: boolean;
};

export const useStories = ({ enabled = true }: UseStories = {}) => {
  const { data, isLoading, isLoadingError } = useQuery({
    queryKey: STORIES_QUERY_KEYS.stories(),
    queryFn: async () => await getStories(),
    enabled: enabled,
  });

  return {
    stories: data,
    isFetchingStories: isLoading,
    didFetchingStoriesErrored: isLoadingError,
  };
};
