// React & Next
import type { ReactElement } from 'react';

// 3rd
import { Flex, Skeleton, SkeletonText, forwardRef } from '@chakra-ui/react';
import type { ComponentWithAs, As } from '@chakra-ui/react';

// App - Types
import type { VariantStylingOptions } from './variants';

// App - Other
import { Text } from '@/components/atoms/typography';
import { InsightVariants, mergeVariantStylingOptions } from './variants';

type InsightComponent = ComponentWithAs<As, InsightProps> & {
  Loading: typeof Loading;
};

type InsightProps = {
  variant?: keyof typeof InsightVariants;
  title: string | ReactElement;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onDoubleClick?: () => void;
  styleOptions?: VariantStylingOptions;
  children?: string | ReactElement;
};

export const Insight = forwardRef(
  (
    {
      variant,
      styleOptions = {},
      title,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onDoubleClick,
      children,
    }: InsightProps,
    ref
  ) => {
    const variantStyleConfig = variant ? InsightVariants[variant] : undefined;
    const styles = mergeVariantStylingOptions(variantStyleConfig, styleOptions);

    return (
      <Flex
        direction="column"
        gap="sm"
        alignItems="flex-start"
        w="100%"
        {...styles.container}
        ref={ref}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDoubleClick={onDoubleClick}
        cursor={onClick ? 'pointer' : 'default'}
      >
        <Flex
          alignSelf="stretch"
          alignItems="center"
          gap="sm"
          py="sm"
          ps="none"
          pe="md"
          borderRadius="sm"
          {...styles.header}
        >
          <Flex
            direction="column"
            alignSelf="stretch"
            alignItems="flex-start"
            w="4px"
            borderTopStartRadius="none"
            borderTopEndRadius="sm"
            borderBottomStartRadius="none"
            borderBottomEndRadius="sm"
            {...styles.lineDecoration}
          />

          <Text
            variant="label"
            display="flex"
            alignItems="center"
            flex="1 0 0"
            py="xxs"
            px="none"
            color="inehrit"
          >
            {title}
          </Text>
        </Flex>

        {children ? (
          <Text variant="body" px="md" {...styles.body}>
            {children}
          </Text>
        ) : null}
      </Flex>
    );
  }
) as InsightComponent;

const Loading = () => {
  return (
    <Flex direction="column" gap="sm" alignItems="flex-start" w="100%">
      <Skeleton height="40px" w="100%" borderRadius="sm" />

      <SkeletonText noOfLines={2} w="100%" px="md" />
    </Flex>
  );
};

Insight.Loading = Loading;
