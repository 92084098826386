// React & Next
import { createElement, useEffect, useRef, useState } from 'react';

// 3rd
import { Flex, ScaleFade, Spinner } from '@chakra-ui/react';

// App - Types
import { Position } from '@/components/molecules/charts/flow';
import type { NodeProps } from '@/components/molecules/charts/flow';
import type { StoriesGraphVertexData } from '../types';

// App - Other
import { useHover } from '@/components/hooks/use-hover';
import { AddIcon, ErrorIcon } from '@/components/atoms/icon';
import { EllipsisText } from '@/components/atoms/typography';
import { IconBox } from '@/components/molecules/icon-box';
import { Handle } from './handle';

type SuggestedKnowledgeItemVertexProps = NodeProps<StoriesGraphVertexData>;

export const SuggestedKnowledgeItemVertex = ({
  data: { title, icon, onAttach, isAttaching, didAttachErrored, isDisabled },
}: SuggestedKnowledgeItemVertexProps) => {
  const [isLoading, setLoading] = useState<boolean>();
  const hoverRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(hoverRef);

  const handleAttachKnowledgeItem = () => {
    setLoading(true);
    onAttach();
  };

  useEffect(() => {
    if (!isAttaching) {
      setLoading(false);
    }
  }, [isAttaching]);

  return (
    <Flex direction="column" position="relative" maxW={`${VERTEX_MAX_WIDTH}px`} ref={hoverRef}>
      <Handle type="target" position={Position.Top} />

      <Handle type="source" position={Position.Bottom} />

      <Handle type="target" position={Position.Left} />

      <Handle type="source" position={Position.Right} />

      <Flex
        alignItems="center"
        gap="sm"
        bg="surface.primary"
        py="sm"
        px="md"
        borderRadius="md"
        boxShadow="elevation-4"
        opacity={0.7}
      >
        <IconBox bg="transparent" w="28px" h="28px" minW="28px" minH="28px" borderRadius="md">
          {createElement(icon, {
            size: 'xs',
            'aria-label': 'KnowledgeItem Source',
          })}
        </IconBox>

        <EllipsisText maxW={`${VERTEX_MAX_WIDTH - 100}px`} variant="label-semibold">
          {title}
        </EllipsisText>
      </Flex>

      <ScaleFade
        initialScale={0.9}
        in={isLoading || isHover}
        style={{
          position: 'absolute',
          top: '-12px',
          right: '-12px',
        }}
      >
        <IconBox
          bg="surface.primary"
          w="28px"
          h="28px"
          minW="28px"
          minH="28px"
          border="3px"
          // borderColor={bgColor}
          borderRadius="full"
          boxShadow="elevation-5"
          onClick={
            !isLoading && !isDisabled && !didAttachErrored ? handleAttachKnowledgeItem : () => {}
          }
        >
          {isLoading ? (
            <Spinner
              // color={bgColor}
              size="xs"
            />
          ) : didAttachErrored ? (
            <ErrorIcon aria-label="Error" size="xs" />
          ) : (
            <AddIcon size="2xs" aria-label="add" />
          )}
        </IconBox>
      </ScaleFade>
    </Flex>
  );
};

export const VERTEX_MAX_WIDTH = 250;
export const EXTENSION_NAME = 'SuggestedKnowledgeItemVertex';
export const EXTENSION = {
  SuggestedKnowledgeItemVertex: SuggestedKnowledgeItemVertex,
};
