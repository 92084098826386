// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { BusinessLogicIssue } from '../../../types/security-analysis';
import type { Threat } from '../../../types/threats-analysis';
import type { Requirement } from '../../../types/requirements-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { APP_URL } from '@/config/constants';

const locale = Locale.features['stories']['translators']['feedback'];

export const prepareJiraFeedbackContent = (
  recipient: { source: KnowledgeSource; author: string },
  threats: Threat[],
  issues: BusinessLogicIssue[],
  requirements: Requirement[],
  href: string,
  includeDescription: boolean
) => {
  let content = '';

  content += '';

  const totalConcerns = threats.length + issues.length + requirements.length;

  content += `*${totalConcerns} ${totalConcerns === 1 ? locale['Security concern'] : locale['Security concerns']}* `;
  content += `${locale['detected during automated security review by 🍀 Clover Security:']}`;
  content += `\r\r`;

  let index = 1;

  threats.forEach((t) => {
    content += `${index}. ${locale['Potential threat']} - ${t.threat}.`;

    index += 1;

    if (includeDescription && t.reasoning) {
      content += '\r\r';
      content += `${locale["Why it's important?"]}`;
      content += '\r';
      content += `${t.reasoning}`;
    }

    content += '\r\r';
  });

  issues.forEach((i) => {
    content += `${index}. ${locale['Business logic issue']} - ${i.issue}`;

    index += 1;

    if (includeDescription && i.reasoning) {
      content += '\r\r';
      content += `${locale["Why it's important?"]}`;
      content += '\r';
      content += `${i.reasoning}`;
    }

    content += '\r\r';
  });

  requirements.forEach((r) => {
    content += `${index}. ${locale['Security requirement']} - ${r.requirement}`;

    index += 1;

    if (includeDescription && r.explanation) {
      content += '\r\r';
      content += `${locale["Why it's important?"]}`;
      content += '\r';
      content += `${r.explanation}`;
    }

    content += '\r\r';
  });

  content += '\n\n';
  content += `${locale['See detailed security design review in Clover']} >>`;
  content += '\r';
  content += `${APP_URL}${href}`;

  return content;
};
