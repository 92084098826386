// 3rd
import { Flex, SkeletonText } from '@chakra-ui/react';

// App - Types
import type { BusinessLogicIssue } from '../../types/security-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { Text } from '@/components/atoms/typography';
import { Tag } from '@/components/molecules/tag';
import { Markdown } from '@/components/organisms/markdown';
import {
  translateThreatSeverity,
  translateThreatSeverityToColor,
} from '@/components/translators/story/threat-severity';

const locale = Locale.widgets.reviews['review-details'];

type StoriesBusinessLogicIssueInformationProps = {
  issue: BusinessLogicIssue;
};

export const StoriesBusinessLogicIssueInformation = ({
  issue,
}: StoriesBusinessLogicIssueInformationProps) => {
  return (
    <Flex direction="column" gap="20px">
      <Text variant="label" color="text.high-tone">
        {locale['Business logic issue']}
      </Text>

      <Text variant="detail" color="text.high-tone">
        {issue.issue}
      </Text>

      <Flex direction="column">
        <Flex alignItems="center" gap="24px">
          <Text variant="detail-semibold" color="text.high-tone">
            {locale['Impact']}
          </Text>

          <Tag size="old-md" bg={translateThreatSeverityToColor(issue.severity)}>
            <Text variant="detail-semibold" color="text.invert-primary">
              {translateThreatSeverity(issue.severity)}
            </Text>
          </Tag>
        </Flex>
      </Flex>

      {issue.reasoning ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale["Why it's important?"]}
          </Text>

          <Text variant="detail" color="text.high-tone" w="95%">
            {issue.reasoning}
          </Text>
        </Flex>
      ) : null}

      {issue.abuseScenario ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['Abuse scenario']}
          </Text>

          <Markdown color="text.high-tone" w="95%">
            {issue.abuseScenario}
          </Markdown>
        </Flex>
      ) : null}
    </Flex>
  );
};

const Loading = () => {
  return <SkeletonText w="100%" noOfLines={12} />;
};

StoriesBusinessLogicIssueInformation.Loading = Loading;
