// App - Types
import type { ThreatSeverity } from '@/types/story/threat-severity';

export type Threat = {
  category: string;
  threat: string;
  reasoning: string;
  likelihoodScore: number;
  generalBackground: string;
  countermeasures: string;
  severityScore: number;
  severity: ThreatSeverity;
};

export const compareThreats = (a: Threat, b: Threat) => {
  return (
    a.category === b.category &&
    a.threat === b.threat &&
    a.reasoning === b.reasoning &&
    a.generalBackground === b.generalBackground &&
    a.countermeasures === b.countermeasures &&
    a.severity === b.severity
  );
};
