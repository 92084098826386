// React & Next
import { useMemo } from 'react';

// App - Types
import type { Edge, Node } from '@/components/molecules/charts/flow';

// App - Other
import { ErrorVertexExtensionName } from '../vertices';

export const usePrepareErrorGraph = (): {
  nodes: Node[];
  edges: Edge[];
} => {
  const nodes: Node[] = useMemo(
    () => [
      {
        id: `error-node`,
        type: ErrorVertexExtensionName,
        position: { x: 0, y: 0 },
        data: {},
      },
    ],
    []
  );

  return {
    nodes,
    edges: [],
  };
};
