// 3rd
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdDtoToId } from '@/types/knowledge-item/dtos/id';
import { compareIds } from '@/types/knowledge-item/id';
import type { Id } from '@/types/knowledge-item/id';
import type {
  RelatedKnowledgeItemsGraph,
  RelatedKnowledgeItemsGraphVertex,
  RelatedKnowledgeItemsGraphEdge,
} from '../../types/story-related-knowledge-items';

// App Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodKnowledgeItemsGraphDto = z.object({
  knowledgeItemEdges: z.array(
    z.object({
      connected: z.boolean(),
      firstItemId: ZodIdDto,
      secondItemId: ZodIdDto,
      directLinkRank: z.number().optional(),
      relativeLink: z.boolean().optional(),
      vectorDistance: z.number().optional(),
      weight: z.number(),
    })
  ),

  knowledgeItemVertices: z.array(
    z.object({
      knowledgeItemId: ZodIdDto,
      author: z.string(),
      lastModified: z.string(),
      title: z.string(),
    })
  ),
});

export type KnowledgeItemsGraphDto = z.infer<typeof ZodKnowledgeItemsGraphDto>;

export const castDtoToRelatedKnowledgeItemsGraph = (
  dto: KnowledgeItemsGraphDto,
  seedKnowledgeItem: Id
): RelatedKnowledgeItemsGraph => {
  const connections: RelatedKnowledgeItemsGraphEdge[] = dto.knowledgeItemEdges.map((edge) => {
    return {
      connected: edge.connected,
      firstItemId: castIdDtoToId(edge.firstItemId),
      secondItemId: castIdDtoToId(edge.secondItemId),
      weight: edge.weight,
      vectorDistance: edge.vectorDistance,
      directLinkRank: edge.directLinkRank,
      isTargetRelativeOfSource: !!edge.relativeLink,
      isTargetVectorDistanceOfSource: !!edge.vectorDistance,
      isTargetDirectLinkOfSource: !!edge.directLinkRank,
    };
  });

  const vertices: RelatedKnowledgeItemsGraphVertex[] = dto.knowledgeItemVertices.map((vertex) => {
    const knowledgeItemId = castIdDtoToId(vertex.knowledgeItemId);

    return {
      knowledgeItemId,
      lastModified: normalizeDatesToISOString(vertex.lastModified),
      title: vertex.title,
      author: vertex.author,
      isSeed: compareIds(seedKnowledgeItem, knowledgeItemId),
    };
  });

  return {
    connections,
    vertices,
  };
};
