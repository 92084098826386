// App - Types
import type { Status, StatusGroup } from '@/types/story/status';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.story.status;

export const translateStatus = (status: Status): string => {
  if (status === 'candidate') return locale['Review candidate'];

  if (status === 'pre_review') return locale['Pre review'];

  if (status === 'ready_for_review') return locale['Ready for review'];

  if (status === 'in_progress') return locale['In review'];

  if (status === 'pending_developers') return locale['Pending developer'];

  if (status === 'pending_security_approval') return locale['Pending security approval'];

  if (status === 'done') return locale['Completed'];

  if (status === 'declined') return locale['Declined'];

  return locale['Unknown'];
};

export const translateStatusGroup = (statusGroup: StatusGroup): string => {
  if (statusGroup === 'PENDING') return locale['Pending'];

  if (statusGroup === 'IN_PROGRESS') return locale['In review'];

  if (statusGroup === 'COMPLETE') return locale['Completed'];

  return locale['Unknown'];
};

export const translateStatusToColor = (status: Status): string => {
  if (status === 'candidate') return '#FDC352';

  if (status === 'pre_review') return '#d67ba8';

  if (status === 'ready_for_review') return '#596AD4';

  if (status === 'in_progress') return '#00CCC9';

  if (status === 'pending_developers') return '#02B1E7';

  if (status === 'pending_security_approval') return '#00CCC9';

  if (status === 'done') return '#8A3CA4';

  if (status === 'declined') return '#DB3B21';

  return '#a0aec0';
};
