// 3rd
import { z } from 'zod';

// App - Types
import type { PotentiallyMissingInformation } from '../../../types/security-analysis';

export const ZodSecurityReviewQuestionnaireDto = z.object({
  criticality: z.number(),
  devQuestionnaire: z.string(),
  reasoning: z.string(),
  securityReviewClarificationPoint: z.string(),
});

export type SecurityReviewQuestionnaireDto = z.infer<typeof ZodSecurityReviewQuestionnaireDto>;

export const castDtoToPotentiallyMissingInformation = (
  dto: SecurityReviewQuestionnaireDto
): PotentiallyMissingInformation => {
  return {
    devQuestionnaire: dto.devQuestionnaire,
    reasoning: dto.reasoning,
    missingInformationPoint: dto.securityReviewClarificationPoint,
  };
};
