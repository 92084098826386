// 3rd
import { z } from 'zod';

// App - Types
import type { RequirementsAnalysis } from '../../../types/requirements-analysis';
import { ZodRequirementDto, castDtoToRequirement } from './requirement';

export const ZodRequirementsAnalysisDto = z.object({
  creationTime: z.string().optional(),
  securityFrameworkRequirements: z.array(ZodRequirementDto).catch((e) => {
    console.error('story requirements analysis parsing errored! see:', e);

    return [];
  }),
});

export type RequirementsAnalysisDto = z.infer<typeof ZodRequirementsAnalysisDto>;

export const castDtoToRequirementsAnalysis = (
  dto: RequirementsAnalysisDto,
  isOldAssessment: boolean
): RequirementsAnalysis => {
  return {
    requirements: dto.securityFrameworkRequirements.map(castDtoToRequirement),
    isOldResults: isOldAssessment,
  };
};
