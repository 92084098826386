// 3rd
import { Flex, SkeletonText } from '@chakra-ui/react';

// App - Types
import type { Threat } from '../../types/threats-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { Text } from '@/components/atoms/typography';
import { Tag } from '@/components/molecules/tag';
import { Markdown } from '@/components/organisms/markdown';
import {
  translateThreatSeverity,
  translateThreatSeverityToColor,
} from '@/components/translators/story/threat-severity';
import { LikelihoodBar } from './components';

const locale = Locale.widgets.reviews['review-details'];

type StoriesThreatInformationProps = {
  threat: Threat;
};

export const StoriesThreatInformation = ({ threat }: StoriesThreatInformationProps) => {
  return (
    <Flex direction="column" gap="20px">
      <Text variant="label" color="text.high-tone">
        {threat.category}
      </Text>

      <Text variant="detail" color="text.high-tone">
        {threat.threat}
      </Text>

      <Flex direction="column">
        <Flex alignItems="center" gap="24px">
          <Text variant="detail-semibold" color="text.high-tone">
            {locale['Impact']}
          </Text>

          <Tag size="old-md" bg={translateThreatSeverityToColor(threat.severity)}>
            <Text variant="detail-semibold" color="text.invert-primary">
              {translateThreatSeverity(threat.severity)}
            </Text>
          </Tag>
        </Flex>
      </Flex>

      <Flex direction="column" pe="32px">
        <Flex alignItems="flex-start" gap="24px">
          <Text variant="detail-semibold" color="text.high-tone" mt="-3px">
            {locale['Likelihood']}
          </Text>

          <LikelihoodBar likelihoodScore={threat.likelihoodScore} />
        </Flex>
      </Flex>

      {threat.reasoning ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale["Why it's important?"]}
          </Text>

          <Text variant="detail" color="text.high-tone" w="95%">
            {threat.reasoning}
          </Text>
        </Flex>
      ) : null}

      {threat.generalBackground ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['Threat general information']}
          </Text>

          <Text variant="detail" color="text.high-tone" w="95%">
            {threat.generalBackground}
          </Text>
        </Flex>
      ) : null}

      {threat.countermeasures ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['What can you do about it?']}
          </Text>

          <Markdown color="text.high-tone" w="95%">
            {threat.countermeasures}
          </Markdown>
        </Flex>
      ) : null}
    </Flex>
  );
};

const Loading = () => {
  return <SkeletonText w="100%" noOfLines={12} />;
};

StoriesThreatInformation.Loading = Loading;
