// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { Story } from '../types/story';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// #######
// Request
// #######

export const deleteStory = async (id: string): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({ securityStoryId: id });
    await apiClient.post(`/SecurityStory/DeleteSecurityStory`, payload);

    return;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseDeleteStory = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useDeleteStory = ({ onStart, onSuccess, onError }: UseDeleteStory = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (id: string) => await deleteStory(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: STORIES_QUERY_KEYS.stories(),
      });
    },
    onMutate: async (mutationPayload) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: STORIES_QUERY_KEYS.stories() });

      // Snapshot the previous value
      const previousStories = (queryClient.getQueryData(STORIES_QUERY_KEYS.stories()) ||
        []) as Story[];
      const newStories = previousStories.filter((story) => story.id !== mutationPayload);

      // Optimistically update to the new value
      queryClient.setQueryData(STORIES_QUERY_KEYS.stories(), newStories);

      // Calling external callbacks
      onStart?.();

      // Return a context with the previous and new data
      return { previousStories, newStories };
    },
    onSuccess: () => onSuccess?.(),
    onError: (error, mutationPayload, context) => {
      // If the mutation fails, use the context we returned above
      queryClient.setQueryData(STORIES_QUERY_KEYS.stories(), context!.previousStories);

      onError?.(error);
    },
  });

  return {
    deleteStory: mutate,
    isDeletingStory: isPending,
    didStoryDeletionErrored: isError,
  };
};
