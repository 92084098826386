// 3rd
import { CircularProgress, Flex } from '@chakra-ui/react';

// App - Types
import type { PotentiallyMissingInformation } from '../../types/security-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { OutlineInfoIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Checkbox } from '@/components/molecules/form';
import { Tooltip } from '@/components/molecules/tooltips';

const locale = Locale.widgets.reviews['review-details'];

type StoriesMissingInformationProps = {
  missingInformation: PotentiallyMissingInformation;
  isDevQuestionnaireChecked: boolean;
  onCheckDevQuestionnaire?: () => void;
};

export const StoriesMissingInformation = ({
  missingInformation,
  isDevQuestionnaireChecked,
  onCheckDevQuestionnaire,
}: StoriesMissingInformationProps) => {
  return (
    <Flex
      gap="sm"
      cursor={onCheckDevQuestionnaire ? 'pointer' : 'default'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        onCheckDevQuestionnaire?.();
      }}
    >
      {onCheckDevQuestionnaire ? <Checkbox isChecked={isDevQuestionnaireChecked} /> : null}

      <Flex direction="column" alignItems="flex-start" gap="xxs">
        <Text variant="detail-semibold" color="text.high-tone">
          {missingInformation.missingInformationPoint}

          {missingInformation.reasoning ? (
            <Tooltip content={missingInformation.reasoning} placement="right">
              <OutlineInfoIcon
                size="xs"
                color="text.high-tone"
                ms="4px"
                aria-label={locale['Info tip']}
              />
            </Tooltip>
          ) : null}
        </Text>
      </Flex>
    </Flex>
  );
};

const Loading = () => {
  // return <SkeletonText noOfLines={4} w="100%" />;
  return <CircularProgress isIndeterminate color="surface.brand.primary" />;
};

StoriesMissingInformation.Loading = Loading;
