// App - Types
import type { KnowledgeSource } from '../integration/knowledge-source';
import type { Type } from './type';

export type Id = {
  source: KnowledgeSource;
  type: Type;
  collectionId: string;
  documentId: string;
};

export const stringifyId = (id: Id): string =>
  `${id.source}~${id.type}~${id.collectionId}~${id.documentId}`;

export const parseId = (id: string): Id => {
  const [source, type, collectionId, documentId] = id.split('~');

  return {
    source: source as KnowledgeSource,
    type: type as Type,
    collectionId,
    documentId,
  };
};

export const compareIds = (a: Id, b: Id): boolean => stringifyId(a) === stringifyId(b);
