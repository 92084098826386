// 3rd
import { delay } from 'msw';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { AsyncTaskStatusDto } from '@/types/common/dtos/async-task';
import { AsyncTaskStatusesDto } from '@/types/common/dtos/async-task';
import type { CoveredRequirementsAnalysis } from '../types/requirements-analysis';
import {
  castCoveredRequirementsAnalysisDtoToCoveredRequirementsAnalysis,
  ZodCoveredRequirementsAnalysisDto,
} from './dtos/covered-requirements-analysis';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  status: z.enum(AsyncTaskStatusesDto).nullable().optional(),
  data: ZodCoveredRequirementsAnalysisDto,
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

const getCoveredRequirementsAnalysis = async (
  storyId: string
): Promise<{
  status: AsyncTaskStatusDto | null;
  assessment: CoveredRequirementsAnalysis | null;
}> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({ securityStoryId: storyId });
    const res = await apiClient.post(`/SecurityStory/GetCoveredRequirements`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return {
      status: parsedRes.status || null,
      assessment: parsedRes.data
        ? castCoveredRequirementsAnalysisDtoToCoveredRequirementsAnalysis(
            parsedRes.data,
            ['Failed', 'FailedCritical'].includes(parsedRes.status || '')
          )
        : null,
    };
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ################
// Analysis Pooling
// ################

const getOrWatchCoveredRequirementsAnalysis = async (storyId: string, signal: AbortSignal) => {
  try {
    let analysis = await getCoveredRequirementsAnalysis(storyId);

    while (
      !signal.aborted &&
      analysis.status &&
      analysis.status !== 'Completed' &&
      analysis.status !== 'Failed'
    ) {
      await delay(10000);

      analysis = await getCoveredRequirementsAnalysis(storyId);
    }

    return analysis.assessment;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCoveredRequirementsAnalysis = {
  storyId?: string;
  enabled?: boolean;
};

export const useCoveredRequirementsAnalysis = ({
  storyId,
  enabled = true,
}: UseCoveredRequirementsAnalysis = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: STORIES_QUERY_KEYS.coveredRequirementsAnalysis(storyId!),
    queryFn: async ({ signal }) => await getOrWatchCoveredRequirementsAnalysis(storyId!, signal),
    enabled: enabled && !!storyId,
  });

  return {
    coveredRequirementsAnalysis: data,
    isFetchingCoveredRequirementsAnalysis: isLoading || isFetching,
    didFetchingCoveredRequirementsAnalysisErrored: isLoadingError,
  };
};
