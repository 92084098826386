// 3rd
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SlackFeedbackRecipient } from '../types/slack-feedback-recipient';
import { ZodSlackRecipientDto, castSlackRecipientDtoToStory } from './dtos/slack-recipient';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  slackUsers: z
    .array(ZodSlackRecipientDto)
    .default([])
    .catch((e) => {
      console.error('possible slack recipients parsing errored! see: ', e);

      return [];
    }),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getStoryEligibleSlackFeedbackRecipients = async (
  id: string
): Promise<SlackFeedbackRecipient[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({ securityStoryId: id });
    const res = await apiClient.post(`/SecurityStory/GetPossibleSlackRecipients`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.slackUsers.map(castSlackRecipientDtoToStory);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseStoryEligibleSlackFeedbackRecipients = {
  reviewId?: string;
  enabled?: boolean;
};

export const useStoryEligibleSlackFeedbackRecipients = ({
  reviewId,
  enabled = true,
}: UseStoryEligibleSlackFeedbackRecipients) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: STORIES_QUERY_KEYS.storyEligibleSlackFeedbackRecipients(reviewId!),
    queryFn: async () => await getStoryEligibleSlackFeedbackRecipients(reviewId!),
    enabled: enabled && !!reviewId,
  });

  return {
    slackRecipients: data,
    isFetchingSlackRecipients: isLoading || isFetching,
    didFetchingSlackRecipientsErrored: isLoadingError,
  };
};
