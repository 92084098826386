// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { BusinessLogicIssue } from '../../../types/security-analysis';
import type { Threat } from '../../../types/threats-analysis';
import type { Requirement } from '../../../types/requirements-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { APP_URL } from '@/config/constants';
import { feedbackPrefix, feedbackSuffix } from './common';

const locale = Locale.features.stories.translators.feedback;

export const prepareConfluenceFeedbackContent = (
  recipient: { source: KnowledgeSource; author: string },
  threats: Threat[],
  issues: BusinessLogicIssue[],
  requirements: Requirement[],
  href: string,
  includeDescription: boolean,
  includeActionsInFeedback: boolean
) => {
  let content = '';

  content += '<p className="feedback-prefix">';
  content += feedbackPrefix(recipient.author);
  content += '</p>';
  content += '\n\n';

  if (threats.length) {
    content += '<table>';
    content += '<tbody>';

    content += '<tr>';

    content += `<th><strong>${locale['Threat']}</strong></th>`;
    content += `<th><strong>${locale['Explanation']}</strong></th>`;

    if (includeDescription) {
      content += `<th><strong>${locale['What can you do about it?']}</strong></th>`;
    }

    if (includeActionsInFeedback) {
      content += `<th><strong>${locale['Actions']}</strong></th>`;
    }

    content += '</tr>';

    threats.forEach((t) => {
      content += '<tr>';

      content += `<td><strong>${t.threat}</strong></td>`;
      content += `<td>${t.reasoning || ''}</td>`;

      if (includeDescription) {
        content += `<td>${t.countermeasures}</td>`;
      }

      if (includeActionsInFeedback) {
        content += `<td>`;
        content += `<a href="${APP_URL}/${href}">👍 ${locale['Mark as WIP']}</a>`;
        content += '  /  ';
        content += `<a href="${APP_URL}/${href}">👎 ${locale['Reject']}</a>`;
        content += '  /  ';
        content += `<a href="${APP_URL}/${href}">🤔 ${locale['See more details']}</a>`;
        content += `</td>`;
      }

      content += '</tr>';
    });

    content += '</tbody>';
    content += '</table>';

    content += '\n';
  }

  if (issues.length) {
    if (threats.length) {
      content += '\n\n';
    }

    content += '<table>';
    content += '<tbody>';

    content += '<tr>';

    content += `<th><strong>${locale['Business logic issue']}</strong></th>`;
    content += `<th><strong>${locale['Explanation']}</strong></th>`;

    if (includeDescription) {
      content += `<th><strong>${locale['What can you do about it?']}</strong></th>`;
    }

    if (includeActionsInFeedback) {
      content += `<th><strong>${locale['Actions']}</strong></th>`;
    }

    content += '</tr>';

    issues.forEach((i) => {
      content += '<tr>';

      content += `<td><strong>${i.issue}</strong></td>`;
      content += `<td>${i.reasoning || ''}</td>`;

      if (includeDescription) {
        content += `<td>${i.abuseScenario || ''}</td>`;
      }

      if (includeActionsInFeedback) {
        content += `<td>`;
        content += `<a href="${APP_URL}/${href}">👍 ${locale['Mark as WIP']}</a>`;
        content += '  /  ';
        content += `<a href="${APP_URL}/${href}">👎 ${locale['Reject']}</a>`;
        content += '  /  ';
        content += `<a href="${APP_URL}/${href}">🤔 ${locale['See more details']}</a>`;
        content += `</td>`;
      }

      content += '</tr>';
    });

    content += '</tbody>';
    content += '</table>';

    content += '\n';
  }

  if (requirements.length) {
    content += '<table>';
    content += '<tbody>';

    content += '<tr>';

    content += `<th><strong>${locale['Security requirement']}</strong></th>`;
    content += `<th><strong>${locale['Explanation']}</strong></th>`;

    if (includeDescription) {
      content += `<th><strong>${locale['What can you do about it?']}</strong></th>`;
    }

    if (includeActionsInFeedback) {
      content += `<th><strong>${locale['Actions']}</strong></th>`;
    }

    content += '</tr>';

    requirements.forEach((r) => {
      content += '<tr>';

      content += `<td><strong>(${r.name}) ${r.requirement}</strong></td>`;
      content += `<td>${r.explanation || ''}</td>`;

      if (includeDescription) {
        if (r.countermeasures.length) {
          content += '<td>';

          r.countermeasures.forEach((cm, index) => {
            content += `-`;
            content += `<strong>${cm.title}</strong>`;
            content += '\r';
            content += `${cm.content}`;

            if (r.countermeasures.length - 1 !== index) {
              content += '\n';
            }
          });

          content += '</td>';
        } else if (r.implementationExample) {
          content += '<td>';
          content += '<code>';
          content += `${r.implementationExample}`;
          content += '</code>';
          content += '</td>';
        }
      }

      if (includeActionsInFeedback) {
        content += `<td>`;
        content += `<a href="${APP_URL}/${href}">👍 ${locale['Mark as WIP']}</a>`;
        content += '  /  ';
        content += `<a href="${APP_URL}/${href}">👎 ${locale['Reject']}</a>`;
        content += '  /  ';
        content += `<a href="${APP_URL}/${href}">🤔 ${locale['See more details']}</a>`;
        content += `</td>`;
      }

      content += '</tr>';
    });

    content += '</tbody>';
    content += '</table>';

    content += '\n';
  }

  content += '\r';
  content += '<p className="feedback-suffix">';
  content += feedbackSuffix();
  content += '</p>';

  return content;
};
