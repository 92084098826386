// 3rd
import { z } from 'zod';

// App - Types
import { castThreatSeverityScoreDtoToThreatSeverity } from '@/types/story/dtos/threat-severity-score';
import type { BusinessLogicIssue } from '../../../types/security-analysis';

export const ZodBusinessLogicIssueDto = z.object({
  issue: z.string(),
  severity: z.number(),
  reasoning: z.string(),
  abuseFlow: z.string(),
});

export type BusinessLogicIssueDto = z.infer<typeof ZodBusinessLogicIssueDto>;

export const castDtoToBusinessLogicIssue = (dto: BusinessLogicIssueDto): BusinessLogicIssue => {
  return {
    issue: dto.issue,
    severityScore: dto.severity,
    severity: castThreatSeverityScoreDtoToThreatSeverity(dto.severity),
    reasoning: dto.reasoning,
    abuseScenario: dto.abuseFlow,
  };
};
