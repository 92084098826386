// React & Next
import { useMemo } from 'react';

// 3rd
import { DateTime } from 'luxon';
import { Flex } from '@chakra-ui/react';

// App - Types
import { StatusGroups } from '@/types/story/status';
import type { Story } from '../../types/story';

// App - Other
import Locale from '@/locale/en.json';
import { Text } from '@/components/atoms/typography';
import { Areas } from '@/components/molecules/charts';

const locale = Locale.features.stories['stories-progress-overtime-card'];

type StoriesProgressAreasChartProps = {
  suggestedStories: Array<{ lastUpdated: DateTime }>;
  stories: Story[];
};

export const StoriesProgressAreasChart = ({
  suggestedStories,
  stories,
}: StoriesProgressAreasChartProps) => {
  const statusGroups = useMemo(() => {
    const pendingReviews = [
      ...suggestedStories,
      ...stories.filter((story) => StatusGroups.PENDING.includes(story.status)),
    ];
    const inProgressReviews = stories.filter((story) =>
      StatusGroups.IN_PROGRESS.includes(story.status)
    );
    const completedReviews = stories.filter((story) =>
      StatusGroups.COMPLETE.includes(story.status)
    );

    return {
      pendingReviews,
      inProgressReviews,
      completedReviews,
    };
  }, [stories, suggestedStories]);

  const data = useMemo(() => {
    const { pendingReviews, inProgressReviews, completedReviews } = statusGroups;

    if (!pendingReviews.length && !inProgressReviews.length && !completedReviews.length) {
      return [statusGroups];
    }

    const earliestDate = DateTime.min(
      ...pendingReviews.map((review) => review.lastUpdated),
      ...inProgressReviews.map((review) => review.lastUpdated),
      ...completedReviews.map((review) => review.lastUpdated)
    );

    const latestDate = DateTime.max(
      ...pendingReviews.map((review) => review.lastUpdated),
      ...inProgressReviews.map((review) => review.lastUpdated),
      ...completedReviews.map((review) => review.lastUpdated)
    );

    const totalWeeks = latestDate.diff(earliestDate, 'weeks').weeks;

    let intervalSize = 1;

    if (totalWeeks > 20) {
      intervalSize = Math.ceil(totalWeeks / 20);
    }

    const dateIntervals: DateTime[] = [];
    let currentWeekStart = earliestDate.startOf('week');

    while (currentWeekStart <= latestDate) {
      dateIntervals.push(currentWeekStart);
      currentWeekStart = currentWeekStart.plus({ weeks: intervalSize });
    }

    if (dateIntervals.length === 1) {
      currentWeekStart = currentWeekStart.minus({ weeks: intervalSize * 2 });
      dateIntervals.unshift(currentWeekStart);
    }

    const isInRange = (
      review: Story | { lastUpdated: DateTime },
      intervalStart: DateTime,
      intervalEnd: DateTime
    ) => {
      return review.lastUpdated >= intervalStart && review.lastUpdated <= intervalEnd;
    };

    return dateIntervals.map((date) => {
      const intervalStart = date;
      const intervalEnd = intervalStart.plus({ weeks: intervalSize }).endOf('week');

      return {
        date: date.toISODate(),
        pendingReviews: pendingReviews.filter((review) =>
          isInRange(review, intervalStart, intervalEnd)
        ).length,
        inProgressReviews: inProgressReviews.filter((review) =>
          isInRange(review, intervalStart, intervalEnd)
        ).length,
        completedReviews: completedReviews.filter((review) =>
          isInRange(review, intervalStart, intervalEnd)
        ).length,
      };
    });
  }, [statusGroups]);

  return (
    <Areas
      data={data}
      areas={[
        {
          dataKey: 'pendingReviews',
          name: locale['Pending'],
          color: '#21A9AB',
          legendLabel: (
            <Flex alignItems="flex-end" gap="3px">
              <Text variant="body" fontWeight={600} color="#21A9AB">
                {statusGroups.pendingReviews.length}
              </Text>

              <Text
                variant="small"
                color="text.high-tone"
                fontWeight={500}
                lineHeight="18px"
                whiteSpace="nowrap"
              >
                {locale['Pending']}
              </Text>
            </Flex>
          ),
        },
        {
          dataKey: 'inProgressReviews',
          name: locale['In progress'],
          color: '#006BBB',
          legendLabel: (
            <Flex alignItems="flex-end" gap="3px">
              <Text variant="body" fontWeight={600} color="#006BBB">
                {statusGroups.inProgressReviews.length}
              </Text>

              <Text
                variant="small"
                color="text.high-tone"
                fontWeight={500}
                lineHeight="18px"
                whiteSpace="nowrap"
              >
                {locale['In progress']}
              </Text>
            </Flex>
          ),
        },
        {
          dataKey: 'completedReviews',
          name: locale['Completed'],
          color: '#8A3CA4',
          legendLabel: (
            <Flex alignItems="flex-end" gap="3px">
              <Text variant="body" fontWeight={600} color="#8A3CA4">
                {statusGroups.completedReviews.length}
              </Text>

              <Text
                variant="small"
                color="text.high-tone"
                fontWeight={500}
                lineHeight="18px"
                whiteSpace="nowrap"
              >
                {locale['Completed']}
              </Text>
            </Flex>
          ),
        },
      ]}
    />
  );
};

const Loading = () => {
  return <Areas.Loading />;
};

StoriesProgressAreasChart.Loading = Loading;
