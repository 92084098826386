// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { StatusesDto, castStatusToStatusDto } from '@/types/story/dtos/status';
import type { Status } from '@/types/story/status';
import type { Story } from '../types/story';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
  status: z.enum(StatusesDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// #######
// Request
// #######

export const changeStoryStatus = async (id: string, status: Status): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityStoryId: id,
      status: castStatusToStatusDto(status),
    });

    return await apiClient.post(`/SecurityStory/UpdateSecurityStoryReviewStatus`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseChangeStoryStatus = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useChangeStoryStatus = ({
  onStart,
  onSuccess,
  onError,
}: UseChangeStoryStatus = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ storyId, status }: { storyId: string; status: Status }) =>
      await changeStoryStatus(storyId, status),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: STORIES_QUERY_KEYS.stories(),
      });
    },
    onMutate: async (mutationPayload) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: STORIES_QUERY_KEYS.stories() });

      // Snapshot the previous value
      const previousStories = (queryClient.getQueryData(STORIES_QUERY_KEYS.stories()) ||
        []) as Story[];

      const newStories = previousStories.map((story) => {
        if (story.id === mutationPayload.storyId) {
          return {
            ...story,
            status: mutationPayload.status,
          };
        }

        return story;
      });

      // Optimistically update to the new value
      queryClient.setQueryData(STORIES_QUERY_KEYS.stories(), newStories);

      // Calling external callbacks
      onStart?.();

      // Return a context with the previous and new data
      return { previousStories, newStories };
    },
    onSuccess: () => onSuccess?.(),
    onError: (error, mutationPayload, context) => {
      // If the mutation fails, use the context we returned above
      queryClient.setQueryData(STORIES_QUERY_KEYS.stories(), context!.previousStories);

      onError?.(error);
    },
  });

  return {
    changeStatus: mutate,
    isChangingStoryStatus: isPending,
    didStoryChangeStatusErrored: isError,
  };
};
