// React & Next
import { useEffect } from 'react';

// 3rd
import {
  forceSimulation,
  forceLink,
  forceManyBody,
  forceCenter,
  forceCollide,
  forceRadial,
} from 'd3-force';
import type { SimulationNodeDatum } from 'd3-force';

// App - Types
import type { ReactFlowState, Node, Edge } from '@/components/molecules/charts/flow';
import type {
  StoriesGraphVertex,
  StoriesGraphVertexData,
  StoriesGraphEdgeData,
  StoriesGraphEdge,
} from '../types';

// App - Other
import { useReactFlow, useStore } from '@/components/molecules/charts/flow';
import { GRAPH_VERTICES_OVERRIDES } from '../vertices';

type StoriesGraphVertexSimulationType = SimulationNodeDatum &
  StoriesGraphVertex & {
    x?: number;
    y?: number;
    fx?: number;
    fy?: number;
    degree: number; // Number of connections within its group
  };

const elementCountSelector = (state: ReactFlowState): number =>
  state.nodeInternals.size + state.edges.length;

const nodesInitializedSelector = (state: ReactFlowState): boolean =>
  Array.from(state.nodeInternals.values()).every((node) => node.width && node.height);

type ForceLayoutProps = {
  canvasWidth: number;
  canvasHeight: number;
  nodeSize: number;
  groupRules?: Array<(node: StoriesGraphVertex) => boolean>;
  shortenEdgesRules?: Array<(edge: StoriesGraphEdge) => boolean>;
};

export const KnowledgeItemsGraphForceLayout = ({
  canvasWidth,
  canvasHeight,
  nodeSize,
  groupRules = [],
  shortenEdgesRules = [],
}: ForceLayoutProps) => {
  const elementCount = useStore(elementCountSelector);
  const nodesInitialized = useStore(nodesInitializedSelector);
  const { setNodes, getNodes, getEdges, fitView } = useReactFlow<
    StoriesGraphVertexData,
    StoriesGraphEdgeData
  >();

  useEffect(() => {
    const nodes: Node<StoriesGraphVertexData>[] = getNodes();
    const edges: Edge<StoriesGraphEdgeData>[] = getEdges();

    if (!nodes.length || !nodesInitialized) {
      return;
    }

    const simulationNodes: StoriesGraphVertexSimulationType[] = nodes.map((node) => {
      const groupIds = groupRules
        .map((rule, idx) => (rule(node as StoriesGraphVertex) ? idx : -1))
        .filter((idx) => idx !== -1);

      const relevantEdges = edges.filter((edge) => {
        const connectedNodeId = edge.source === node.id ? edge.target : edge.source;
        const connectedNode = nodes.find((n) => n.id === connectedNodeId);

        return (
          connectedNode &&
          groupRules.some(
            (rule, idx) => groupIds.includes(idx) && rule(connectedNode as StoriesGraphVertex)
          )
        );
      });

      return {
        ...node,
        type: node.type as keyof typeof GRAPH_VERTICES_OVERRIDES,
        degree: relevantEdges.length,
        groups: groupIds,
      };
    });

    const groupCenters = groupRules.map((rule) => {
      const groupNodes = simulationNodes.filter((node) => rule(node));
      const centerNode = groupNodes.reduce(
        (a, b) => (a.degree > b.degree ? a : b),
        groupNodes[0] || { x: canvasWidth / 2, y: canvasHeight / 2 }
      );
      const radius = Math.max(50, 10 * Math.sqrt(groupNodes.length)); // Dynamic radius based on the number of nodes

      return { center: { x: centerNode.x, y: centerNode.y }, radius };
    });

    const simulation = forceSimulation(simulationNodes)
      .force(
        'link',
        forceLink(edges)
          .id((d) => (d as StoriesGraphVertexSimulationType).id)
          .distance((link) => {
            const isShortened = shortenEdgesRules.some((rule) => rule(link as StoriesGraphEdge));

            return isShortened ? 0.5 : 100;
          })
      )
      .force('charge', forceManyBody().strength(-100)) // Reduced strength to prevent excessive dispersion
      .force('collision', forceCollide(nodeSize * 0.7)) // Prevent overlap with some padding
      .force('center', forceCenter(canvasWidth / 2, canvasHeight / 2))
      .velocityDecay(0.86);

    groupCenters.forEach((group, index) => {
      simulation.force(
        `group${index}`,
        forceRadial(group.radius, group.center.x, group.center.y).strength(0.1)
      );
    });

    simulation
      .on('tick', () => {
        fitView({ includeHiddenNodes: true });

        setNodes((prevNodes) =>
          prevNodes.map((node, i) => ({
            ...node,
            position: { x: simulationNodes[i].x ?? 0, y: simulationNodes[i].y ?? 0 },
          }))
        );
      })
      .on('end', () => {
        fitView({ includeHiddenNodes: true });
      });

    return () => {
      simulation.stop();
    };
  }, [
    elementCount,
    getNodes,
    getEdges,
    setNodes,
    nodesInitialized,
    canvasWidth,
    canvasHeight,
    fitView,
    nodeSize,
    groupRules,
    shortenEdgesRules,
  ]);

  return <></>;
};
