// React & Next
import { useMemo } from 'react';

// 3rd
import { Box } from '@chakra-ui/react';

// App - Types
import type { Id } from '@/types/knowledge-item/id';
import type { RelatedKnowledgeItemsGraph } from '../../types/story-related-knowledge-items';

// App - Other
import { Flow } from '@/components/molecules/charts/flow';
import { usePrepareKnowledgeItemsGraph } from './hooks/use-prepare-knowledge-items-graph';
import { usePrepareLoadingGraph } from './hooks/use-prepare-loading-graph';
import { usePrepareEmptyGraph } from './hooks/use-prepare-empty-graph';
import { usePrepareErrorGraph } from './hooks/use-prepare-error-graph';
import { GRAPH_EDGES_OVERRIDES, SuggestedConnectionExtensionName } from './connections';
import {
  GRAPH_VERTICES_OVERRIDES,
  SuggestedKnowledgeItemVertexMaxWidth,
  LinkedKnowledgeItemVertexMaxWidth,
  LoadingVertexMaxWidth,
  ErrorVertexMaxWidth,
  EmptyVertexMaxWidth,
  LinkedKnowledgeItemVertexExtensionName,
  SuggestedKnowledgeItemVertexExtensionName,
} from './vertices';
import { KnowledgeItemsGraphForceLayout, NodesPropsSync } from './layout';

type StoriesKnowledgeItemsFlowGraphProps = {
  relatedKnowledgeItemsGraph: RelatedKnowledgeItemsGraph;
  currentUser?: string;
  onAttachKnowledgeItem?: (srcKnowledgeItemId: Id, destKnowledgeItemId: Id) => void;
  isAttachingKnowledgeItem: boolean;
  didAttachKnowledgeItemErrored: boolean;
  canvasWidth: number;
  canvasHeight: number;
  treatGoogleDriveAsOneDrive?: boolean;
};

export const StoriesKnowledgeItemsFlowGraph = ({
  relatedKnowledgeItemsGraph,
  onAttachKnowledgeItem,
  isAttachingKnowledgeItem,
  didAttachKnowledgeItemErrored,
  canvasWidth,
  canvasHeight,
  treatGoogleDriveAsOneDrive,
}: StoriesKnowledgeItemsFlowGraphProps) => {
  const graph = usePrepareKnowledgeItemsGraph({
    relatedKnowledgeItemsGraph,
    onAttachKnowledgeItem: (...args) => {
      onAttachKnowledgeItem?.(...args);
    },
    treatGoogleDriveAsOneDrive,
  });

  const { nodes, edges } = useMemo(() => {
    if (onAttachKnowledgeItem) return graph;

    return {
      nodes: graph.nodes.filter((node) => node.type !== SuggestedKnowledgeItemVertexExtensionName),
      edges: graph.edges.filter((node) => node.type !== SuggestedConnectionExtensionName),
    };
  }, [graph, onAttachKnowledgeItem]);

  return (
    <Box
      key={`review-context-graph-${!!onAttachKnowledgeItem}`}
      width={`${canvasWidth}px`}
      height={`${canvasHeight}px`}
      bg="surface.primary"
      border="1px"
      borderColor="border.primary"
      borderRadius="sm"
    >
      <Flow
        vertices={nodes}
        connections={edges}
        nodeExtensions={GRAPH_VERTICES_OVERRIDES}
        edgeExtensions={GRAPH_EDGES_OVERRIDES}
      >
        <KnowledgeItemsGraphForceLayout
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          nodeSize={Math.max(
            SuggestedKnowledgeItemVertexMaxWidth,
            LinkedKnowledgeItemVertexMaxWidth
          )}
          groupRules={[
            (node) =>
              node.data.knowledgeItemId.source === 'jira' &&
              node.type === LinkedKnowledgeItemVertexExtensionName,
            (node) =>
              node.data.knowledgeItemId.source === 'jira' &&
              node.type === SuggestedKnowledgeItemVertexExtensionName,
          ]}
          shortenEdgesRules={[(edge) => edge.type === SuggestedConnectionExtensionName]}
        />

        <NodesPropsSync
          isAttachingKnowledgeItem={isAttachingKnowledgeItem}
          didAttachKnowledgeItemErrored={didAttachKnowledgeItemErrored}
        />
      </Flow>
    </Box>
  );
};

type LoadingProps = {
  canvasWidth: number;
  canvasHeight: number;
};

const Loading = ({ canvasWidth, canvasHeight }: LoadingProps) => {
  const { nodes, edges } = usePrepareLoadingGraph({
    nodesLength: 4,
  });

  return (
    <Box
      width={`${canvasWidth}px`}
      height={`${canvasHeight}px`}
      bg="surface.primary"
      border="1px"
      borderColor="border.primary"
      borderRadius="sm"
    >
      <Flow
        vertices={nodes}
        connections={edges}
        nodeExtensions={GRAPH_VERTICES_OVERRIDES}
        edgeExtensions={GRAPH_EDGES_OVERRIDES}
      >
        <KnowledgeItemsGraphForceLayout
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          nodeSize={LoadingVertexMaxWidth}
        />
      </Flow>
    </Box>
  );
};

StoriesKnowledgeItemsFlowGraph.Loading = Loading;

type EmptyProps = {
  canvasWidth: number;
  canvasHeight: number;
};

const Empty = ({ canvasWidth, canvasHeight }: EmptyProps) => {
  const { nodes, edges } = usePrepareEmptyGraph();

  return (
    <Box
      width={`${canvasWidth}px`}
      height={`${canvasHeight}px`}
      bg="surface.primary"
      border="1px"
      borderColor="border.primary"
      borderRadius="sm"
    >
      <Flow vertices={nodes} connections={edges} nodeExtensions={GRAPH_VERTICES_OVERRIDES}>
        <KnowledgeItemsGraphForceLayout
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          nodeSize={EmptyVertexMaxWidth}
        />
      </Flow>
    </Box>
  );
};

StoriesKnowledgeItemsFlowGraph.Empty = Empty;

type ErrorProps = {
  canvasWidth: number;
  canvasHeight: number;
};

const Error = ({ canvasWidth, canvasHeight }: ErrorProps) => {
  const { nodes, edges } = usePrepareErrorGraph();

  return (
    <Box
      width={`${canvasWidth}px`}
      height={`${canvasHeight}px`}
      bg="surface.primary"
      border="1px"
      borderColor="border.primary"
      borderRadius="sm"
    >
      <Flow vertices={nodes} connections={edges} nodeExtensions={GRAPH_VERTICES_OVERRIDES}>
        <KnowledgeItemsGraphForceLayout
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          nodeSize={ErrorVertexMaxWidth}
        />
      </Flow>
    </Box>
  );
};

StoriesKnowledgeItemsFlowGraph.Error = Error;
