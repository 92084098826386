// 3rd
import { z } from 'zod';

// App - Types
import type { CoveredRequirement } from '../../../types/requirements-analysis';

export const ZodCoveredRequirementDto = z.object({
  categoryName: z.string().optional(),
  reasoning: z.string(),
  securityControlName: z.string(),
  securityFrameworkName: z.string(),
  requirementIdNumber: z.string(),
  securityRequirement: z.string(),
});

export type CoveredRequirementDto = z.infer<typeof ZodCoveredRequirementDto>;

export const castCoveredRequirementDtoToCoveredRequirement = (
  dto: CoveredRequirementDto
): CoveredRequirement => {
  return {
    name: dto.requirementIdNumber,
    framework: dto.securityFrameworkName,
    control: dto.securityControlName,
    category: dto.categoryName || undefined,
    requirement: dto.securityRequirement,
    reasoning: dto.reasoning,
  };
};
