// 3rd
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import type { Id } from '@/types/knowledge-item/id';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  knowledgeItemId: ZodIdDto,
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityStoriesIds: z
    .string()
    .array()
    .default([])
    .catch((e) => {
      console.error('security stories parsing errored! see: ', e);

      return [];
    }),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getRelatedStoriesIds = async (knowledgeItemId: Id): Promise<string[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      knowledgeItemId: castIdToIdDto(knowledgeItemId),
    });
    const res = await apiClient.post(
      `/SecurityStory/GetSecurityStoriesIdsRelatedToKnowledgeItem`,
      payload
    );
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityStoriesIds;
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseRelatedStoriesIds = {
  knowledgeItemId?: Id;
  enabled?: boolean;
};

export const useRelatedStoriesIds = ({
  knowledgeItemId,
  enabled = true,
}: UseRelatedStoriesIds = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: STORIES_QUERY_KEYS.relatedStoriesToKnowledgeItem(
      knowledgeItemId?.collectionId || '',
      knowledgeItemId?.documentId || ''
    ),
    queryFn: async () => await getRelatedStoriesIds(knowledgeItemId!),
    enabled:
      enabled &&
      !!knowledgeItemId &&
      !!knowledgeItemId.source &&
      !!knowledgeItemId.collectionId &&
      !!knowledgeItemId.documentId,
  });

  return {
    relatedStoriesIds: data,
    isFetchingRelatedStoriesIds: isLoading || isFetching,
    didFetchingRelatedStoriesIdsErrored: isLoadingError,
  };
};
