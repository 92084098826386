// 3rd
import { z } from 'zod';

// App - Types
import type { RiskIdentification } from '../../../types/security-analysis';

export const ZodRiskIdentificationDto = z.object({
  attackScenario: z.string(),
  confidence: z.number(),
  reasoning: z.string(),
  risk: z.string(),
});

export type RiskIdentificationDto = z.infer<typeof ZodRiskIdentificationDto>;

export const castDtoToRiskIdentification = (dto: RiskIdentificationDto): RiskIdentification => {
  return {
    attackScenario: dto.attackScenario,
    confidence: dto.confidence,
    reasoning: dto.reasoning,
    risk: dto.risk,
  };
};
