// React & Next
import { useMemo } from 'react';

// 3rd
import { Flex, Circle, SkeletonCircle, Skeleton } from '@chakra-ui/react';

// App - Types
import { StatusGroups, StatusGroupsKeys } from '@/types/story/status';
import type { StatusGroup } from '@/types/story/status';
import type { Story } from '../../types/story';

// App - Other
import { Text } from '@/components/atoms/typography';
import { translateStatus } from '@/components/translators/story/status';

type StoriesStatusBarProps = {
  story: Story;
};

export const StoriesStatusBar = ({ story }: StoriesStatusBarProps) => {
  const activeStatusLabel: StatusGroup = useMemo(() => {
    const findStatusGroup = Object.entries(StatusGroups).find(([, statuses]) => {
      return statuses.includes(story.status);
    });

    if (!findStatusGroup) {
      return 'PENDING';
    }

    return findStatusGroup[0] as StatusGroup;
  }, [story.status]);

  const isFilled = (statusGroup: StatusGroup) => {
    return StatusGroupsKeys.indexOf(statusGroup) <= StatusGroupsKeys.indexOf(activeStatusLabel);
  };

  return (
    <Flex direction="column" alignItems="center" gap="xs">
      <Flex align="center" gap="sm">
        {StatusGroupsKeys.map((group) => (
          <Circle
            key={group}
            // size={activeStatusLabel === label ? '18px' : '14px'}
            size="14px"
            bg={isFilled(group) ? 'surface.brand.primary' : 'surface.low-tone'}
          />
        ))}
      </Flex>

      <Text variant="detail" color="text.brand.primary-high-tone" whiteSpace="nowrap">
        {translateStatus(story.status)}
      </Text>
    </Flex>
  );
};

const Loading = () => {
  // const [activeLabelIndex, setActiveLabelIndex] = useState(0);
  // const [, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
  //   countStart: 0,
  //   countStop: 1,
  //   intervalMs: 200,
  //   isIncrement: true,
  //   onCountdownFinish: () => {
  //     // setActiveLabelIndex((prev) => (prev + 1 > statusLabels.length - 1 ? 0 : prev + 1));
  //     resetCountdown();
  //     startCountdown();
  //   },
  // });
  //
  // useEffect(() => {
  //   startCountdown();
  //
  //   return () => stopCountdown();
  // }, [startCountdown, stopCountdown]);

  return (
    <Flex direction="column" alignItems="center" gap="xs" w="100%">
      <Flex align="center" gap="sm" h="18px">
        {StatusGroupsKeys.map((label) => (
          <SkeletonCircle
            key={label}
            // size={activeLabelIndex === index ? '18px' : '14px'}
            size="14px"
            transition="all 120ms ease-in-out"
          />
        ))}
      </Flex>

      <Skeleton w="60px" h="20px" />
    </Flex>
  );
};

StoriesStatusBar.Loading = Loading;

const Empty = () => {
  return (
    <Flex direction="column" alignItems="center" gap="xs">
      <Flex align="center" gap="sm">
        {StatusGroupsKeys.map((label) => (
          <Circle
            key={label}
            // size={activeStatusLabel === label ? '18px' : '14px'}
            size="14px"
            bg="surface.low-tone"
          />
        ))}
      </Flex>

      <Text varint="detail" color="text.mid-tone" whiteSpace="nowrap">
        -
      </Text>
    </Flex>
  );
};

StoriesStatusBar.Empty = Empty;
