// 3rd
import { Flex, SkeletonText } from '@chakra-ui/react';

// App - Types
import type { CoveredRequirement } from '../../types/requirements-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { Text } from '@/components/atoms/typography';
import { Tag } from '@/components/molecules/tag';

const locale = Locale.widgets.reviews['review-details'];

type StoriesCoveredRequirementInformationProps = {
  coveredRequirement: CoveredRequirement;
};

export const StoriesCoveredRequirementInformation = ({
  coveredRequirement,
}: StoriesCoveredRequirementInformationProps) => {
  return (
    <Flex direction="column" gap="20px">
      <Flex direction="column">
        <Text variant="label" color="text.high-tone">
          {coveredRequirement.control}
        </Text>

        {coveredRequirement.category ? (
          <Text variant="detail" color="text.high-tone">
            {coveredRequirement.category}
          </Text>
        ) : null}
      </Flex>

      <Text variant="detail" color="text.high-tone">
        ({coveredRequirement.name}) {coveredRequirement.requirement}
      </Text>

      <Flex direction="column">
        <Flex alignItems="center">
          <Tag size="old-md" bg="surface.success">
            <Text variant="detail-semibold" color="text.invert-primary">
              {locale['Covered']}
            </Text>
          </Tag>
        </Flex>
      </Flex>

      {coveredRequirement.reasoning ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['Reasoning behind this assessment']}
          </Text>

          <Text variant="detail" color="text.high-tone" w="95%">
            {coveredRequirement.reasoning}
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );
};

const Loading = () => {
  return <SkeletonText w="100%" noOfLines={12} />;
};

StoriesCoveredRequirementInformation.Loading = Loading;
