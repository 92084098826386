// 3rd
import { z } from 'zod';

// App - Types
import type { RiskAreaAndAttackSurface } from '../../../types/security-analysis';

export const ZodRiskAreaAndAttackSurfaceDto = z.object({
  explanation: z.string(),
  riskArea: z.string(),
});

export type RiskAreaAndAttackSurfaceDto = z.infer<typeof ZodRiskAreaAndAttackSurfaceDto>;

export const castDtoToRiskAreaAndAttackSurface = (
  dto: RiskAreaAndAttackSurfaceDto
): RiskAreaAndAttackSurface => {
  return {
    explanation: dto.explanation,
    riskArea: dto.riskArea,
  };
};
