// React & Next
import { createElement, useMemo } from 'react';

// 3rd
import { Flex, Skeleton, VStack } from '@chakra-ui/react';

// App -Types
import type { StoryPotentiallyRelatedKnowledgeItem } from '../../types/story-related-knowledge-items';

// App - Other
import Locale from '@/locale/en.json';
import { randomInt } from '@/utils/generators';
import { OutlineInfoIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Tooltip } from '@/components/molecules/tooltips';
import { Checkbox, CheckboxGroup } from '@/components/molecules/form';
import { translateKnowledgeSourceToIcon } from '@/components/translators/integration/knowledge-source';
import { translatePotentiallyRelatedKnowledgeItemType } from '@/components/translators/story/potentially-related-knowledge-item-type';

const locale = Locale.widgets.reviews['review-details'];

type StoriesPotentiallyRelatedItemsChecklistProps = {
  potentiallyRelatedItems: StoryPotentiallyRelatedKnowledgeItem[];
  onChange: (selected: StoryPotentiallyRelatedKnowledgeItem[]) => void;
  treatGoogleDriveAsOneDrive?: boolean;
};

export const StoriesPotentiallyRelatedItemsChecklist = ({
  potentiallyRelatedItems,
  onChange,
  treatGoogleDriveAsOneDrive,
}: StoriesPotentiallyRelatedItemsChecklistProps) => {
  const itemsMap = useMemo(() => {
    return potentiallyRelatedItems.reduce(
      (acc, item) => {
        acc[
          `${item.knowledgeItemId.source}-${item.knowledgeItemId.collectionId}-${item.knowledgeItemId.documentId}`
        ] = item;

        return acc;
      },
      {} as Record<string, StoryPotentiallyRelatedKnowledgeItem>
    );
  }, [potentiallyRelatedItems]);

  return (
    <CheckboxGroup
      onChange={(checked) => {
        const checkedEnriched = checked.flatMap((id) => (itemsMap[id] ? [itemsMap[id]] : []));
        onChange(checkedEnriched);
      }}
    >
      <VStack alignItems="flex-start" gap="sm">
        {potentiallyRelatedItems.map((item) => (
          <Checkbox
            key={`${item.knowledgeItemId.source}-${item.knowledgeItemId.collectionId}-${item.knowledgeItemId.documentId}`}
            value={`${item.knowledgeItemId.source}-${item.knowledgeItemId.collectionId}-${item.knowledgeItemId.documentId}`}
          >
            <Flex alignItems="center" gap="xs">
              {createElement(
                translateKnowledgeSourceToIcon(
                  item.knowledgeItemId.source,
                  treatGoogleDriveAsOneDrive
                ),
                {
                  size: 'xs',
                  'aria-label': locale['Item’s source'],
                }
              )}

              <Text variant="body">
                {item.title}

                <Tooltip
                  content={translatePotentiallyRelatedKnowledgeItemType(item.type)}
                  placement="right"
                >
                  <OutlineInfoIcon
                    size="xs"
                    color="text.high-tone"
                    ms="4px"
                    aria-label={locale['Info tip']}
                  />
                </Tooltip>
              </Text>
            </Flex>
          </Checkbox>
        ))}
      </VStack>
    </CheckboxGroup>
  );
};

const Loading = () => {
  return (
    <VStack alignItems="flex-start" gap="sm">
      {Array.from({ length: 7 }).map((_, i) => (
        <Flex key={i} alignItems="center" gap="lg">
          <Skeleton w="16px" h="16px" borderRadius="xs" />

          <Skeleton w={`${randomInt(130, 380)}px`} h="20px" borderRadius="xs" />
        </Flex>
      ))}
    </VStack>
  );
};

StoriesPotentiallyRelatedItemsChecklist.Loading = Loading;
