// 3rd
import { z } from 'zod';

// App - Types
import type { CoveredRequirementsAnalysis } from '../../../types/requirements-analysis';
import {
  ZodCoveredRequirementDto,
  castCoveredRequirementDtoToCoveredRequirement,
} from './covered-requirement';

export const ZodCoveredRequirementsAnalysisDto = z.array(ZodCoveredRequirementDto).catch((e) => {
  console.error('covered requirements analysis parsing errored! see:', e);

  return [];
});

export type CoveredRequirementsAnalysisDto = z.infer<typeof ZodCoveredRequirementsAnalysisDto>;

export const castCoveredRequirementsAnalysisDtoToCoveredRequirementsAnalysis = (
  dto: CoveredRequirementsAnalysisDto,
  isOldAssessment: boolean
): CoveredRequirementsAnalysis => {
  return {
    coveredRequirements: dto.map(castCoveredRequirementDtoToCoveredRequirement),
    isOldResults: isOldAssessment,
  };
};
