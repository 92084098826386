// React & Next
import { useMemo } from 'react';

// App - Types
import type { Edge, Node } from '@/components/molecules/charts/flow';

// App - Other
import { SuggestedConnectionExtensionName } from '../connections';
import { LoadingVertexExtensionName } from '../vertices';

type UsePrepareLoadingGraph = {
  nodesLength: number;
};

export const usePrepareLoadingGraph = ({
  nodesLength,
}: UsePrepareLoadingGraph): {
  nodes: Node[];
  edges: Edge[];
} => {
  const nodes: Node[] = useMemo(
    () =>
      Array.from({ length: nodesLength }, (_, i) => ({
        id: `loading-node-${i}`,
        type: LoadingVertexExtensionName,
        position: { x: 0, y: 0 },
        data: {},
      })),
    [nodesLength]
  );

  const edges: Edge[] = useMemo(() => {
    const target = nodes[0].id;

    return nodes.slice(1).map((node) => ({
      id: `loading-edge-${node.id}`,
      source: node.id,
      target,
      type: SuggestedConnectionExtensionName,
      animated: true,
    }));
  }, [nodes]);

  return {
    nodes,
    edges,
  };
};
