// App - Types
import type { PotentiallyRelatedKnowledgeItemType } from '@/types/story/potentially-related-knowledge-item-type';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.features.stories.translators['context-suggestion-type'];

export const translatePotentiallyRelatedKnowledgeItemType = (
  type: PotentiallyRelatedKnowledgeItemType
): string => {
  if (type === 'link') return locale['Direct link'];

  if (type === 'relative') return locale['Relative'];

  if (type === 'distance') return locale['Similarity'];

  return locale['Unknown'];
};
