// 3rd
import { Skeleton, forwardRef } from '@chakra-ui/react';
import type { ComponentWithAs, As } from '@chakra-ui/react';

// App - Types
import type { Threat } from '../../types/threats-analysis';

// App - Other
import { Text } from '@/components/atoms/typography';
import { Insight } from '@/components/molecules/insight';
import { translateThreatSeverityToColor } from '@/components/translators/story/threat-severity';

type StoriesThreatInsightPropsComponent = ComponentWithAs<As, StoriesThreatInsightProps> & {
  Loading: typeof Loading;
};

type StoriesThreatInsightProps = {
  threat: Threat;
  isPinned: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onDoubleClick: () => void;
};

export const StoriesThreatInsight = forwardRef(
  (
    {
      threat,
      isPinned,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onDoubleClick,
    }: StoriesThreatInsightProps,
    ref
  ) => {
    return (
      <Insight
        title={
          <Text
            variant="detail"
            display="flex"
            alignItems="center"
            flex="1 0 0"
            py="xxs"
            px="none"
            color="text.secondary"
          >
            {threat.threat}
          </Text>
        }
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDoubleClick={onDoubleClick}
        ref={ref}
        styleOptions={{
          container: {
            border: '1px',
            borderColor: 'border.primary',
            borderRadius: 'sm',
            cursor: 'pointer',
          },

          header: {
            transition: 'all 150ms ease-in-out',
            bg: isPinned ? 'surface.secondary' : 'transparent',
            py: 'xs',

            _hover: {
              bg: 'surface.secondary',
            },
          },

          lineDecoration: {
            my: '2px',
            bg: translateThreatSeverityToColor(threat.severity),
          },
        }}
      />
    );
  }
) as StoriesThreatInsightPropsComponent;

const Loading = () => {
  return <Skeleton h="48px" w="100%" />;
};

StoriesThreatInsight.Loading = Loading;
