// React & Next
import { useMemo } from 'react';

// 3rd
import { DateTime } from 'luxon';

// App - Types
import {
  castSignificanceDtoToScore,
  castSignificanceToSignificanceDto,
} from '@/types/knowledge-item/dtos/significance';
import type { Kind } from '@/types/knowledge-item/kind';
import type { Labels } from '@/types/knowledge-item/labels';
import type { Significance } from '@/types/knowledge-item/significance';
import type { Classification } from '@/types/knowledge-item/classification';
import type { StoryCandidatesPreferences } from '../../types/story-candidates-preferences';

// App - Other
import { useLocalStorage } from '@/components/hooks/use-local-storage';
import { STORIES_LOCAL_STORAGE_KEYS } from '../../config/local-storage-key-factory';

type StoryCandidateBase = {
  kind: Kind;
  labels: Labels;
  creationTime: DateTime;
  lastUpdated: DateTime;
  significance: Significance;
  classification: Classification;
};

type StoryCandidate<T> = StoryCandidateBase & T;

type UseFilterStoryCandidatesByCachedPreferences<T> = {
  candidates: StoryCandidate<T>[];
};
export const useFilterStoryCandidatesByCachedPreferences = <T>({
  candidates,
}: UseFilterStoryCandidatesByCachedPreferences<T>) => {
  const [preferences] = useLocalStorage<StoryCandidatesPreferences | undefined>(
    STORIES_LOCAL_STORAGE_KEYS.candidatesPreferences(),
    undefined
  );

  return useMemo(
    () =>
      candidates.filter((candidate) => {
        if (preferences) {
          let shouldFilter = false;

          if (!shouldFilter && preferences.kinds && preferences.kinds.length) {
            shouldFilter = !preferences.kinds.includes(candidate.kind);
          }

          if (!shouldFilter && preferences.labels && preferences.labels.length) {
            shouldFilter = !preferences.labels.some((label) => !!candidate.labels[label]);
          }

          if (
            !shouldFilter &&
            preferences.createdFromInDays &&
            preferences.createdFromInDays !== -1
          ) {
            const now = DateTime.now();
            const daysSinceCandidateWasCreated = now.diff(candidate.creationTime, 'days').days;

            shouldFilter = preferences.createdFromInDays <= daysSinceCandidateWasCreated;
          }

          if (
            !shouldFilter &&
            preferences.lastModifiedFromInDays &&
            preferences.lastModifiedFromInDays !== -1
          ) {
            const now = DateTime.now();
            const daysSinceCandidateWasUpdated = now.diff(candidate.lastUpdated, 'days').days;

            shouldFilter = preferences.lastModifiedFromInDays <= daysSinceCandidateWasUpdated;
          }

          if (!shouldFilter && preferences.minSignificanceScore) {
            const candidateSignificanceScore = castSignificanceDtoToScore(
              castSignificanceToSignificanceDto(candidate.significance)
            );

            shouldFilter = preferences.minSignificanceScore >= candidateSignificanceScore;
          }

          if (!shouldFilter && preferences.classifications && preferences.classifications.length) {
            shouldFilter = !preferences.classifications.includes(candidate.classification);
          }

          return !shouldFilter;
        }

        return true;
      }),
    [candidates, preferences]
  );
};
