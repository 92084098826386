// 3rd
import { z } from 'zod';

// App - Types
import type { SecurityAnalysis } from '../../../types/security-analysis';
import {
  ZodAddressedSecurityConsiderationDto,
  castDtoToAddressedSecurityConsideration,
} from './addressed-security-consideration';
import { ZodBusinessLogicIssueDto, castDtoToBusinessLogicIssue } from './business-logic-issue';
import {
  ZodRiskAreaAndAttackSurfaceDto,
  castDtoToRiskAreaAndAttackSurface,
} from './risk-area-and-attack-surface';
import { ZodRiskIdentificationDto, castDtoToRiskIdentification } from './risk-identification';
import {
  ZodSecurityReviewQuestionnaireDto,
  castDtoToPotentiallyMissingInformation,
} from './security-review-questionnaire';

export const ZodSecurityAnalysisDto = z.object({
  creationTime: z.string(),
  securityAssessment: z
    .object({
      addressedSecurityConsiderations: z.array(ZodAddressedSecurityConsiderationDto).catch((e) => {
        console.error('addressed security considerations parsing errored! see: ', e);

        return [];
      }),
      businessLogicIssues: z.array(ZodBusinessLogicIssueDto).catch((e) => {
        console.error('business logic issues parsing errored! see: ', e);

        return [];
      }),
      riskAreasAndAttackSurfaces: z.array(ZodRiskAreaAndAttackSurfaceDto).catch((e) => {
        console.error('risk areas and attack surfaces parsing errored! see: ', e);

        return [];
      }),
      riskIdentifications: z.array(ZodRiskIdentificationDto).catch((e) => {
        console.error('risk identifications parsing errored! see: ', e);

        return [];
      }),
      securityReviewQuestionnaires: z.array(ZodSecurityReviewQuestionnaireDto).catch((e) => {
        console.error('security review questionnaires parsing errored! see: ', e);

        return [];
      }),
    })
    .nullable()
    .optional(),
});

export type SecurityAnalysisDto = z.infer<typeof ZodSecurityAnalysisDto>;

export const castDtoToSecurityAnalysis = (
  dto: SecurityAnalysisDto,
  isOldAssessment: boolean
): SecurityAnalysis => {
  if (!dto.securityAssessment) {
    return {
      addressedSecurityConsiderations: [],
      businessLogicIssues: [],
      riskAreasAndAttackSurfaces: [],
      riskIdentifications: [],
      potentiallyMissingInformation: [],
      isOldResults: isOldAssessment,
    };
  }

  const {
    addressedSecurityConsiderations,
    businessLogicIssues,
    riskAreasAndAttackSurfaces,
    riskIdentifications,
    securityReviewQuestionnaires,
  } = dto.securityAssessment;

  return {
    addressedSecurityConsiderations: addressedSecurityConsiderations.map(
      castDtoToAddressedSecurityConsideration
    ),
    businessLogicIssues: businessLogicIssues.map(castDtoToBusinessLogicIssue),
    riskAreasAndAttackSurfaces: riskAreasAndAttackSurfaces.map(castDtoToRiskAreaAndAttackSurface),
    riskIdentifications: riskIdentifications.map(castDtoToRiskIdentification),
    potentiallyMissingInformation: securityReviewQuestionnaires.map(
      castDtoToPotentiallyMissingInformation
    ),
    isOldResults: isOldAssessment,
  };
};
