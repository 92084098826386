// 3rd
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { Story } from '../types/story';
import { ZodStoryDto, castDtoToStory } from './dtos/story';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityStory: ZodStoryDto,
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getStory = async (id: string): Promise<Story> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({ securityStoryId: id });
    const res = await apiClient.post(`/SecurityStory/GetSecurityStory`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return castDtoToStory(parsedRes.securityStory);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseStory = {
  reviewId?: string;
  enabled?: boolean;
};

export const useStory = ({ reviewId, enabled = true }: UseStory) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: STORIES_QUERY_KEYS.story(reviewId!),
    queryFn: async () => await getStory(reviewId!),
    enabled: enabled && !!reviewId,
  });

  return {
    story: data,
    isFetchingStory: isLoading || isFetching,
    didFetchingStoryErrored: isLoadingError,
  };
};
