// 3rd
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdDtoToId } from '@/types/knowledge-item/dtos/id';
import { castTypeDtoToType } from '@/types/knowledge-item/dtos/type';
import { castTypeToKind } from '@/types/knowledge-item/kind';
import type { StoryPotentiallyRelatedKnowledgeItem } from '../../types/story-related-knowledge-items';

export const ZodPotentiallyRelatedKnowledgeItemDto = z.object({
  knowledgeItemId: ZodIdDto,
  distance: z.number(),
  knowledgeItemTitle: z.string(),
});

export type PotentiallyRelatedKnowledgeItemDto = z.infer<
  typeof ZodPotentiallyRelatedKnowledgeItemDto
>;

export const castPotentiallyRelatedKnowledgeItemDtoToPotentiallyRelatedKnowledgeItemDto = (
  dto: PotentiallyRelatedKnowledgeItemDto
): StoryPotentiallyRelatedKnowledgeItem => {
  const kind = castTypeToKind(castTypeDtoToType(dto.knowledgeItemId.knowledgeItemType));

  return {
    knowledgeItemId: castIdDtoToId(dto.knowledgeItemId),
    kind,
    type: 'distance',
    title: dto.knowledgeItemTitle,
  };
};
