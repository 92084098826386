// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.features.stories.translators['likelihood'];

export const translateLikelihoodScore = (likelihoodScore: number): string => {
  if (likelihoodScore === 1) return locale['Rare'];

  if (likelihoodScore === 2) return locale['Unlikely'];

  if (likelihoodScore === 3) return locale['Possible'];

  if (likelihoodScore === 4) return locale['Probable'];

  if (likelihoodScore === 5) return locale['Imminent'];

  return '';
};
