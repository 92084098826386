// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { BusinessLogicIssue } from '../../../types/security-analysis';
import type { Threat } from '../../../types/threats-analysis';
import type { Requirement } from '../../../types/requirements-analysis';

// App - Other
import { prepareAzureDevOpsWikiPageFeedbackContent } from './azure-devops-wiki-page-feedback';
import { prepareAzureDevOpsWorkItemFeedbackContent } from './azure-devops-work-item-feedback';
import { prepareConfluenceFeedbackContent } from './confluence-feedback';
import { prepareJiraFeedbackContent } from './jira-feedback';
import { prepareGoogleDriveFeedbackContent } from './google-drive-feedback';

export const translateComponentsToCommentFeedback = (
  recipient: { source: KnowledgeSource; author: string },
  threats: Threat[],
  issues: BusinessLogicIssue[],
  requirements: Requirement[],
  href: string,
  includeDescription: boolean,
  author: string,
  includeActionsInFeedback: boolean
): string => {
  if (recipient.source === 'confluence')
    return prepareConfluenceFeedbackContent(
      recipient,
      threats,
      issues,
      requirements,
      href,
      includeDescription,
      includeActionsInFeedback
    );

  if (recipient.source === 'jira')
    return prepareJiraFeedbackContent(
      recipient,
      threats,
      issues,
      requirements,
      href,
      includeDescription
    );

  if (recipient.source === 'google_drive')
    return prepareGoogleDriveFeedbackContent(
      recipient,
      threats,
      issues,
      requirements,
      href,
      includeDescription
    );

  if (recipient.source === 'azure_devops_wiki_page')
    return prepareAzureDevOpsWikiPageFeedbackContent(
      recipient,
      threats,
      issues,
      requirements,
      href,
      includeDescription,
      author,
      includeActionsInFeedback
    );

  if (recipient.source === 'azure_devops_work_item')
    return prepareAzureDevOpsWorkItemFeedbackContent(
      recipient,
      threats,
      issues,
      requirements,
      href,
      includeDescription,
      author,
      includeActionsInFeedback
    );

  return prepareGoogleDriveFeedbackContent(
    recipient,
    threats,
    issues,
    requirements,
    href,
    includeDescription
  );
};
