// 3rd
import { DateTime } from 'luxon';
import { Flex } from '@chakra-ui/react';

// App - Types
import type { MRT_ColumnDef } from '@/components/molecules/data-table';
import type { Id } from '@/types/knowledge-item/id';
import type { Status } from '@/types/story/status';
import type { Story } from '../../types/story';

// App - Other
import Locale from '@/locale/en.json';
import { DocumentIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Tag } from '@/components/molecules/tag';
import { translateStatus, translateStatusToColor } from '@/components/translators/story/status';

const locale = Locale.features.stories['stories-table'];

export const columnsDefs: MRT_ColumnDef<Story>[] = [
  {
    id: 'lastUpdated',
    header: locale['Last modified'],
    accessorKey: 'lastUpdated',
    size: 185,
    Cell: ({ cell }) => {
      const date = cell.getValue<DateTime>();

      return <Text variant="detail">{date.toLocaleString(DateTime.DATE_MED)}</Text>;
    },
  },
  {
    id: 'stories',
    header: locale['Security reviews'],
    accessorKey: 'name',
    size: 500,
    Cell: ({ cell, row }) => {
      const name = cell.getValue<string>();
      const isPendingSecurityApproval = row.original.status === 'pending_security_approval';

      return (
        <Text variant="detail">
          {name}{' '}
          {isPendingSecurityApproval ? (
            <Tag size="old-sm" color="#005E93" bg="#EDF7FF" mt="2px" ms="4px">
              {locale['Pending approval']}
            </Tag>
          ) : null}
        </Text>
      );
    },
  },
  {
    id: 'status',
    header: locale['Status'],
    accessorKey: 'status',
    filterFn: (row, _columnIds, filterValue) => {
      return filterValue
        .toLowerCase()
        .includes(translateStatus(row.getValue<Status>('status')).toLowerCase());
    },
    size: 185,
    Cell: ({ cell }) => {
      const status = cell.getValue<Status>();

      return (
        <Text variant="detail" color={translateStatusToColor(status)}>
          {translateStatus(status)}
        </Text>
      );
    },
  },
  {
    id: 'related-docs',
    header: locale['Related documents'],
    accessorKey: 'relatedKnowledgeItemsIds',
    size: 250,
    filterFn: (row, _columnIds, filterValue) => {
      if (!filterValue) return true;

      const parsedFilterValue = parseInt(filterValue);
      const relatedDocs = row.getValue<Id[]>('related-docs');
      const relatedDocsCount = relatedDocs.length + 1; // + 1 for seed item

      return parsedFilterValue <= relatedDocsCount;
    },
    Cell: ({ cell }) => {
      const relatedDocs = cell.getValue<Id[]>() || [];
      const relatedDocsCount = relatedDocs.length + 1; // + 1 for seed item

      return (
        <Flex alignItems="center" gap="2px">
          <DocumentIcon size="xs" color="text.high-tone" aria-label={locale['Related documents']} />

          <Text variant="detail">{relatedDocsCount}</Text>
        </Flex>
      );
    },
  },
  {
    id: 'application',
    header: locale['Application'],
    accessorKey: 'applicationName',
    size: 250,

    Cell: ({ cell }) => {
      const applicationName = cell.getValue<string>();

      if (!applicationName) {
        return (
          <Text variant="detail" color="text.invert-secondary" fontStyle="italic">
            {locale['No application']}
          </Text>
        );
      }

      return <Text variant="detail">{applicationName}</Text>;
    },
  },
];
