// App - Types
import { stringifyId } from '@/types/knowledge-item/id';
import type { Id } from '@/types/knowledge-item/id';
import type {
  RelatedKnowledgeItemsGraph,
  RelatedKnowledgeItemsGraphVertex,
} from '../../../types/story-related-knowledge-items';
import type { StoriesGraphVertex, StoriesGraphEdge } from '../types';

// App - Other
import {
  translateKnowledgeSourceToColor,
  translateKnowledgeSourceToIcon,
} from '@/components/translators/integration/knowledge-source';
import { SuggestedConnectionExtensionName, LinkedConnectionExtensionName } from '../connections';
import {
  SuggestedKnowledgeItemVertexExtensionName,
  LinkedKnowledgeItemVertexExtensionName,
  GRAPH_VERTICES_OVERRIDES,
} from '../vertices';

type UsePrepareKnowledgeItemsGraph = {
  relatedKnowledgeItemsGraph: RelatedKnowledgeItemsGraph;
  onAttachKnowledgeItem: (srcKnowledgeItemId: Id, destKnowledgeItemId: Id) => void;
  treatGoogleDriveAsOneDrive?: boolean;
};

export const usePrepareKnowledgeItemsGraph = ({
  relatedKnowledgeItemsGraph,
  onAttachKnowledgeItem,
  treatGoogleDriveAsOneDrive,
}: UsePrepareKnowledgeItemsGraph): {
  nodes: StoriesGraphVertex[];
  edges: StoriesGraphEdge[];
} => {
  const graphSeedNode = relatedKnowledgeItemsGraph.vertices.find((vertex) => vertex.isSeed);

  if (!graphSeedNode) {
    return {
      nodes: [],
      edges: [],
    };
  }

  // Process connections
  const { hardLinkNodeMap, suggestedLinkNodeMap } = processConnections(
    graphSeedNode,
    relatedKnowledgeItemsGraph.connections
  );

  // Combine hard and suggested links
  const edges = uniqueEdges([
    ...Array.from(hardLinkNodeMap.values()).flatMap((edges) => edges),
    ...Array.from(suggestedLinkNodeMap.values()).flatMap((edges) => edges),
  ]);

  // Process nodes
  const nodes = processNodes(
    relatedKnowledgeItemsGraph.vertices,
    hardLinkNodeMap,
    suggestedLinkNodeMap,
    onAttachKnowledgeItem,
    treatGoogleDriveAsOneDrive
  );

  return {
    nodes,
    edges,
  };
};

const processConnections = (
  seedKnowledgeItemId: RelatedKnowledgeItemsGraphVertex,
  connections: RelatedKnowledgeItemsGraph['connections']
) => {
  const graphSeedItemId = stringifyId(seedKnowledgeItemId.knowledgeItemId);
  const hardLinkNodeMap = new Map<string, StoriesGraphEdge[]>();
  const suggestedLinkNodeMap = new Map<string, StoriesGraphEdge[]>();

  connections.forEach((connection) => {
    const sourceId = stringifyId(connection.secondItemId);
    const targetId = stringifyId(connection.firstItemId);
    const baseEdge = {
      id: `e${targetId}-${sourceId}`,
      source: sourceId,
      target: targetId,
      data: {
        sourceKnowledgeItemId: connection.secondItemId,
        targetKnowledgeItemId: connection.firstItemId,
        weight: connection.weight,
        isTargetRelativeOfSource: connection.isTargetRelativeOfSource,
        isTargetVectorDistanceOfSource: connection.isTargetVectorDistanceOfSource,
        isTargetDirectLinkOfSource: connection.isTargetDirectLinkOfSource,
      },
    };

    if (connection.connected) {
      if (hardLinkNodeMap.has(sourceId)) {
        const currentEdges = hardLinkNodeMap.get(sourceId)!;

        hardLinkNodeMap.set(sourceId, [
          ...currentEdges,
          { ...baseEdge, type: LinkedConnectionExtensionName },
        ]);
      } else {
        hardLinkNodeMap.set(sourceId, [{ ...baseEdge, type: LinkedConnectionExtensionName }]);
      }

      if (hardLinkNodeMap.has(targetId)) {
        const currentEdges = hardLinkNodeMap.get(targetId)!;

        hardLinkNodeMap.set(targetId, [
          ...currentEdges,
          { ...baseEdge, type: LinkedConnectionExtensionName },
        ]);
      } else {
        hardLinkNodeMap.set(targetId, [{ ...baseEdge, type: LinkedConnectionExtensionName }]);
      }
    } else {
      if (
        sourceId !== graphSeedItemId &&
        !hardLinkNodeMap.has(sourceId) &&
        !hardLinkNodeMap.has(targetId)
      ) {
        // Ignore edges between two suggested nodes
        return;
      }

      const currentEdge = suggestedLinkNodeMap.get(targetId);

      if (
        !currentEdge ||
        currentEdge[0].data.weight < connection.weight ||
        (currentEdge[0].data.weight === connection.weight && connection.directLinkRank)
      ) {
        suggestedLinkNodeMap.set(targetId, [
          { ...baseEdge, type: SuggestedConnectionExtensionName },
        ]);
      }
    }
  });

  return { hardLinkNodeMap, suggestedLinkNodeMap };
};

const processNodes = (
  vertices: RelatedKnowledgeItemsGraph['vertices'],
  hardLinkNodeMap: Map<string, StoriesGraphEdge[]>,
  suggestedLinkNodeMap: Map<string, StoriesGraphEdge[]>,
  onAttachKnowledgeItem: (srcKnowledgeItemId: Id, destKnowledgeItemId: Id) => void,
  treatGoogleDriveAsOneDrive?: boolean
) => {
  return vertices.map((vertex) => {
    const id = stringifyId(vertex.knowledgeItemId);
    const nodeType =
      vertex.isSeed || hardLinkNodeMap.has(id)
        ? LinkedKnowledgeItemVertexExtensionName
        : SuggestedKnowledgeItemVertexExtensionName;

    const suggestedEdge = suggestedLinkNodeMap.get(id);
    const onAttach = suggestedEdge
      ? () =>
          onAttachKnowledgeItem(suggestedEdge[0].data.sourceKnowledgeItemId, vertex.knowledgeItemId)
      : () => {};

    return {
      id,
      type: nodeType as keyof typeof GRAPH_VERTICES_OVERRIDES,
      position: { x: 0, y: 0 },
      data: {
        knowledgeItemId: vertex.knowledgeItemId,
        isSeed: vertex.isSeed,
        title: vertex.title,
        icon: translateKnowledgeSourceToIcon(
          vertex.knowledgeItemId.source,
          treatGoogleDriveAsOneDrive
        ),
        sx: {
          bg: translateKnowledgeSourceToColor(
            vertex.knowledgeItemId.source,
            treatGoogleDriveAsOneDrive
          ),
        },
        onAttach,
        isAttaching: false,
        didAttachErrored: false,
        isDisabled: false,
      },
    };
  });
};

const uniqueEdges = (edges: StoriesGraphEdge[]) => {
  const seen = new Set<string>();

  return edges.filter((edge) => {
    const id = edge.id;

    if (seen.has(id)) {
      return false;
    }

    seen.add(id);

    return true;
  });
};
