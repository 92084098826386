// 3rd
import { Flex } from '@chakra-ui/react';

// App - Other
import Locale from '@/locale/en.json';
import { CancelIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';

const locale = Locale.features.stories['stories-knowledge-items-flow-graph'];

export const EmptyVertex = () => {
  return (
    <Flex
      alignItems="center"
      gap="sm"
      bg="surface.low-contrast"
      border="none"
      borderRadius="md"
      py="xxs"
      px="sm"
      boxShadow="elevation-5"
      maxW={`${VERTEX_MAX_WIDTH}px`}
    >
      <Flex
        direction="column"
        alignItems="flex-start"
        gap="xxs"
        bg="surface.primary"
        py="sm"
        px="md"
        borderRadius="sm"
      >
        <Text variant="body" color="text.high-tone">
          {locale['This graph is empty at the moment.']}
        </Text>

        <Text variant="body" color="text.high-tone">
          {locale['Why not check back later for updates?']}
        </Text>
      </Flex>

      <CancelIcon aria-label="KnowledgeItem Type" color="text.invert-primary" size="sm" />
    </Flex>
  );
};

export const VERTEX_MAX_WIDTH = 300;
export const EXTENSION_NAME = 'EmptyVertex';
export const EXTENSION = {
  EmptyVertex: EmptyVertex,
};
