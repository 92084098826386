// React & Next
import { useMemo } from 'react';

// 3rd
import { Flex, SkeletonText } from '@chakra-ui/react';

// App - Other
import { Text } from '@/components/atoms/typography';
import { Markdown, cleanMarkdown } from '@/components/organisms/markdown';

type StoriesSummaryProps = {
  title: string;
  summary: string;
};

export const StoriesSummary = ({ title, summary }: StoriesSummaryProps) => {
  const cleanedSummary = useMemo(() => {
    if (!summary) return '';

    const cleanedTitle = title.replace('  ', ' ');
    let cleanedSummary = cleanMarkdown(summary, [
      cleanedTitle || '',
      '```markdown',
      `${cleanedTitle}**`,
      `${cleanedTitle}__`,
      `${cleanedTitle} Summary`,
      `${cleanedTitle} Title`,
      `Document Title: ${cleanedTitle}`,
      `Document Title ${cleanedTitle}`,
      `Document Title:`,
      `Document Title`,
      `Title: ${cleanedTitle}`,
      `Title ${cleanedTitle}`,
      'Title:',
      'Title**',
      'Title',
      `Summary: ${cleanedTitle || ''}`,
      `Summary ${cleanedTitle || ''}`,
      `Summary:`,
      `Summary**`,
      `Summary`,
    ]);

    if (cleanedTitle && cleanedTitle.includes('.docx')) {
      const strippedTitle = cleanedTitle.replace('.docx', '');

      cleanedSummary = cleanMarkdown(cleanedSummary, [
        strippedTitle || '',
        `${strippedTitle}**`,
        `${strippedTitle}__`,
        `${strippedTitle} Summary`,
        `${strippedTitle} Title`,
        `Document Title: ${strippedTitle}`,
        `Document Title ${strippedTitle}`,
        `Title: ${strippedTitle}`,
        `Title ${strippedTitle}`,
        `Summary: ${strippedTitle || ''}`,
        `Summary ${strippedTitle || ''}`,
      ]);
    }

    return cleanedSummary;
  }, [summary, title]);

  return (
    <Markdown
      direction="column"
      alignSelf="stretch"
      gap="xxs"
      w="100%"
      overrides={{
        p: {
          component: Text,
          props: {
            className: 'p',
            variant: 'detail',
            color: 'text.high-tone',

            _notFirst: {
              '&:has(.strong)': {
                mt: 'md',
              },
            },
          },
        },
      }}
    >
      {cleanedSummary}
    </Markdown>
  );
};

const Loading = () => {
  return (
    <Flex direction="column" alignSelf="stretch" gap="xxs" w="100%">
      <SkeletonText mt="8px" noOfLines={7} />
    </Flex>
  );
};

StoriesSummary.Loading = Loading;
