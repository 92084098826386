// 3rd
import type { FlexProps, StyleProps } from '@chakra-ui/react';

export type VariantStylingOptions = {
  container?: FlexProps;

  lineDecoration?: StyleProps & {
    bg?: string;
  };

  header?: FlexProps & {
    bg?: string;
    color?: string;
  };

  body?: StyleProps & {
    color?: string;
  };
};

const baseVariant: VariantStylingOptions = {
  container: {},

  lineDecoration: {
    bg: 'surface.invert-mid-tone',
  },

  header: {
    bg: 'surface.secondary',
    color: 'text.secondary',
  },

  body: {
    color: 'text.high-tone',
  },
};

const primaryVariant: VariantStylingOptions = {
  container: {},

  lineDecoration: {
    bg: 'surface.brand.primary',
  },

  header: {
    bg: 'surface.brand.invert-primary-low-tone',
    color: 'text.brand.primary-high-tone',
  },

  body: {
    color: 'text.high-tone',
  },
};

const secondaryVariant: VariantStylingOptions = {
  container: {},

  lineDecoration: {
    bg: 'surface.brand.secondary',
  },

  header: {
    bg: 'surface.brand.secondary-low-tone',
    color: 'text.brand.secondary-high-tone',
  },

  body: {
    color: 'text.high-tone',
  },
};

export const InsightVariants = {
  primary: primaryVariant,
  secondary: secondaryVariant,
};

export const mergeVariantStylingOptions = (
  base: VariantStylingOptions = baseVariant,
  variant: VariantStylingOptions = {}
): VariantStylingOptions => {
  return {
    container: { ...base.container, ...variant.container },

    lineDecoration: { ...base.lineDecoration, ...variant.lineDecoration },

    header: { ...base.header, ...variant.header },

    body: { ...base.body, ...variant.body },
  };
};
