// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import type { Id } from '@/types/knowledge-item/id';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
  knowledgeItemIds: z.array(ZodIdDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// #######
// Request
// #######

export const assignRelatedItemsToStory = async (
  storyId: string,
  knowledgeItemIds: Id[]
): Promise<void> => {
  try {
    if (knowledgeItemIds.length === 0) {
      return;
    }

    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityStoryId: storyId,
      knowledgeItemIds: knowledgeItemIds.map(castIdToIdDto),
    });

    return await apiClient.post(`/SecurityStory/AttachKnowledgeItems`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseAssignRelatedItemsToStory = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useAssignRelatedItemsToStory = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseAssignRelatedItemsToStory = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({
      storyId,
      knowledgeItemIds,
    }: {
      storyId: string;
      knowledgeItemIds: Id[];
    }) => await assignRelatedItemsToStory(storyId, knowledgeItemIds),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: STORIES_QUERY_KEYS.story(variables.storyId!),
      });
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    assignRelatedItemsToStory: mutate,
    isRelatedItemsBeingAssignedToStory: isPending,
    didRelatedItemsAssignmentToStoryErrored: isError,
  };
};
