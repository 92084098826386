// React & Next
import { useMemo } from 'react';

// 3rd
import { Flex, MenuItem } from '@chakra-ui/react';

// App - Types
import type { FetchRequest, MutateRequest } from '@/types/request';
import type { Status } from '@/types/story/status';
import type { Story } from '../../types/story';

// App - Other
import Locale from '@/locale/en.json';
import { EyeIcon, EyeOffIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Button } from '@/components/molecules/button';
import { DataTable } from '@/components/molecules/data-table';
import { columnsDefs } from './columns.defs';

const locale = Locale.features.stories['stories-table'];

type StoriesTableProps = {
  changeStatusRequest: MutateRequest<{
    storyId: string;
    status: Status;
  }>;
  deleteStoryRequest: MutateRequest<{
    storyId: string;
  }>;
  storiesRequest: FetchRequest<Story[]>;
  onRowClick: (row: Story, isCtrlKeyPressed: boolean) => void;
};

export const StoriesTable = ({
  changeStatusRequest,
  deleteStoryRequest,
  storiesRequest,
  onRowClick,
}: StoriesTableProps) => {
  const { data, isLoading, isLoadingError } = storiesRequest;
  const sortedData = useMemo(
    () => (data || []).sort((a, b) => (b.lastUpdated > a.lastUpdated ? 1 : -1)),
    [data]
  );
  // const tableConfig = useTableUrlPersistence({ queryParamsPrefix: 'art-' });
  const columns = useMemo(() => columnsDefs, []);

  const handleStatusTransition = (storyId: string, status: Status) => {
    changeStatusRequest.mutate({ storyId, status });
  };

  const handleDeleteStory = (storyId: string) => {
    deleteStoryRequest.mutate({ storyId });
  };

  return (
    <DataTable
      columns={columns}
      data={sortedData}
      initialState={{
        showGlobalFilter: true,
        columnFilters: [
          {
            id: 'status',
            value:
              'Review candidate, Pre review, Ready for review, In Review, Pending Developer, Pending approval',
          },
        ],
      }}
      // onColumnFiltersChange={tableConfig.setColumnFilters}
      // onGlobalFilterChange={tableConfig.setGlobalFilter}
      // onPaginationChange={tableConfig.setPagination}
      // onSortingChange={tableConfig.setSorting}
      state={{
        showSkeletons: isLoading,
        showAlertBanner: isLoadingError,
        showProgressBars: changeStatusRequest.isLoading,
        // columnFilters: tableConfig.columnFilters,
        // globalFilter: tableConfig.globalFilter,
        // sorting: tableConfig.sorting,
        // pagination: tableConfig.pagination,
      }}
      enableRowActions={true}
      enableHiding={false}
      enableColumnActions={false}
      positionActionsColumn={'last' as 'first' | 'last'}
      renderTopToolbarCustomActions={({ table }) => {
        const statusColFilterValue = table.getColumn('status').getFilterValue();
        const isShowingNotCompleted =
          statusColFilterValue &&
          statusColFilterValue ===
            'Review candidate, Pre review, Ready for review, In Review, Pending Developer, Pending approval';
        const affectedRows = table.getPreFilteredRowModel().rows.filter((row) => {
          const filters = Object.entries(row.columnFilters);

          return (
            (row.original.status === 'done' || row.original.status === 'declined') &&
            filters.every(([key, value]) => (key === 'status' && !value) || !!value)
          );
        });

        return (
          <Flex alignItems="center" gap="8px">
            <Button
              variant="old.outline"
              size="sm"
              aria-label={locale['Hide completed']}
              isActive={!!isShowingNotCompleted}
              leftIcon={
                !!isShowingNotCompleted ? (
                  <EyeOffIcon aria-label={locale['Hiding completed']} />
                ) : (
                  <EyeIcon aria-label={locale['Showing completed']} />
                )
              }
              // sx={
              //   isShowingNotCompleted
              //     ? {
              //         borderColor: 'button.outline.text',
              //         bg: 'button.outline.hover.bg',
              //       }
              //     : {}
              // }
              onClick={() =>
                table.setColumnFilters((prev) => {
                  const noneDocsFilters = prev.filter((f) => f.id !== 'status');

                  return isShowingNotCompleted
                    ? [...noneDocsFilters]
                    : [
                        ...noneDocsFilters,
                        {
                          id: 'status',
                          value:
                            'Review candidate, Pre review, Ready for review, In Review, Pending Developer, Pending approval',
                        },
                      ];
                })
              }
            >
              {locale['Hide completed']} ({`${affectedRows.length}`})
            </Button>
          </Flex>
        );
      }}
      renderRowActionMenuItems={({ row }) => [
        ...(row.original.status === 'done'
          ? [
              <MenuItem
                key="bring-back-to-review"
                isDisabled={changeStatusRequest.isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleStatusTransition(row.original.id, 'in_progress');
                }}
              >
                <Text variant="detail">{locale['Reopen review']}</Text>
              </MenuItem>,
            ]
          : [
              <MenuItem
                key="finish"
                isDisabled={changeStatusRequest.isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleStatusTransition(row.original.id, 'done');
                }}
              >
                <Text variant="detail">{locale['Finish review']}</Text>
              </MenuItem>,
            ]),
        [
          <MenuItem
            key="delete"
            isDisabled={deleteStoryRequest.isLoading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              handleDeleteStory(row.original.id);
            }}
            _hover={{ bg: '#FDE2E2' }}
          >
            <Text variant="detail" color="text.error">
              {locale['Delete review']}
            </Text>
          </MenuItem>,
        ],
      ]}
      toolbarAlertBannerProps={
        isLoadingError
          ? {
              status: 'error',
              variant: 'subtle',
              children: (
                <Flex alignItems="center" h="100%" mb="-6px">
                  <Text variant="detail">
                    {locale['Whoops, something went wrong, contact support.']}
                  </Text>
                </Flex>
              ),
            }
          : {}
      }
      tableBodyRowProps={({ row }) => ({
        onClick: (e) =>
          changeStatusRequest.isLoading ? null : onRowClick(row.original, e.metaKey || e.ctrlKey),
        sx: {
          cursor: changeStatusRequest.isLoading ? 'not-allowed' : 'pointer',
        },
      })}
    />
  );
};
