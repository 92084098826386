/* eslint-disable @typescript-eslint/no-unused-vars */

export const Statuses = [
  'candidate',
  'pre_review',
  'ready_for_review',
  'in_progress',
  'pending_developers',
  'pending_security_approval',
  'done',
  'declined',
  'UNKNOWN',
] as const;

export type Status = (typeof Statuses)[number];

export const StatusGroups = {
  PENDING: ['candidate'],
  IN_PROGRESS: [
    'pre_review',
    'ready_for_review',
    'in_progress',
    'pending_developers',
    'pending_security_approval',
  ],
  COMPLETE: ['done', 'declined'],
};

export type StatusGroup = keyof typeof StatusGroups;

export const StatusGroupsKeys = ['PENDING', 'IN_PROGRESS', 'COMPLETE'] as const;

type StatusGroupsKeysType = (typeof StatusGroupsKeys)[number];
type KeyOfStatusGroups = keyof typeof StatusGroups;
type MissingKeysFromStatusGroupsKeysList = Exclude<KeyOfStatusGroups, StatusGroupsKeysType>;
type ValidateAllKeysAreInStatusGroupsKeysList = MissingKeysFromStatusGroupsKeysList extends never
  ? true
  : never;

const isAllKeysFromStatusGroupsInStatusGroupsKeys: ValidateAllKeysAreInStatusGroupsKeysList = true;
