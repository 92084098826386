export const translateLikelihoodScoreToColor = (likelihoodScore: number): string => {
  if (likelihoodScore === 1) return '#D8E0E5';

  if (likelihoodScore === 2) return '#C8E6E7';

  if (likelihoodScore === 3) return '#34C2BD';

  if (likelihoodScore === 4) return '#21A9AB';

  if (likelihoodScore === 5) return '#136C6D';

  return '';
};
