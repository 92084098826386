// App - Other
import { EXTENSION as SuggestedKnowledgeItemVertexExtension } from './suggested-knowledge-item-vertex';
import { EXTENSION as LinkedKnowledgeItemVertexExtension } from './linked-knowledge-item-vertex';
import { EXTENSION as LoadingVertexExtension } from './loading-vertex';
import { EXTENSION as EmptyVertexExtension } from './empty-vertex';
import { EXTENSION as ErrorVertexExtension } from './error-vertex';

export {
  EXTENSION_NAME as SuggestedKnowledgeItemVertexExtensionName,
  VERTEX_MAX_WIDTH as SuggestedKnowledgeItemVertexMaxWidth,
} from './suggested-knowledge-item-vertex';
export {
  EXTENSION_NAME as LinkedKnowledgeItemVertexExtensionName,
  VERTEX_MAX_WIDTH as LinkedKnowledgeItemVertexMaxWidth,
} from './linked-knowledge-item-vertex';
export {
  EXTENSION_NAME as LoadingVertexExtensionName,
  VERTEX_MAX_WIDTH as LoadingVertexMaxWidth,
} from './loading-vertex';
export {
  EXTENSION_NAME as EmptyVertexExtensionName,
  VERTEX_MAX_WIDTH as EmptyVertexMaxWidth,
} from './empty-vertex';
export {
  EXTENSION_NAME as ErrorVertexExtensionName,
  VERTEX_MAX_WIDTH as ErrorVertexMaxWidth,
} from './error-vertex';

export const GRAPH_VERTICES_OVERRIDES = {
  ...SuggestedKnowledgeItemVertexExtension,
  ...LinkedKnowledgeItemVertexExtension,
  ...LoadingVertexExtension,
  ...EmptyVertexExtension,
  ...ErrorVertexExtension,
};
