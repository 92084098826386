// 3rd
import { CircularProgress, Flex } from '@chakra-ui/react';

// App - Types
import type { AddressedSecurityConsideration } from '../../types/security-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { CheckIcon, OutlineInfoIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Tooltip } from '@/components/molecules/tooltips';

const locale = Locale.features.stories['stories-security-consideration'];

type StoriesSecurityConsiderationProps = {
  securityConsideration: AddressedSecurityConsideration;
};

export const StoriesSecurityConsideration = ({
  securityConsideration,
}: StoriesSecurityConsiderationProps) => {
  return (
    <Flex alignItems="center" gap="sm">
      <CheckIcon size="2xs" aria-label={locale['Security consideration']} />

      <Flex direction="column" alignItems="flex-start" gap="xxs">
        <Text variant="detail-semibold" color="text.high-tone">
          {securityConsideration.consideration}

          {securityConsideration.reasoning ? (
            <Tooltip content={securityConsideration.reasoning} placement="right">
              <OutlineInfoIcon
                size="xs"
                color="text.high-tone"
                ms="4px"
                aria-label={locale['Info tip']}
              />
            </Tooltip>
          ) : null}
        </Text>
      </Flex>
    </Flex>
  );
};

const Loading = () => {
  // return <SkeletonText noOfLines={4} w="100%" />;
  return <CircularProgress isIndeterminate color="surface.brand.primary" />;
};

StoriesSecurityConsideration.Loading = Loading;
