// App - Types
import type { RequirementPriority } from '../requirement-priority';

export const castRequirementPriorityScoreDtoToRequirementPriority = (
  dto: number
): RequirementPriority => {
  if (dto <= 2.5) return 'low';

  if (dto <= 4.5) return 'medium';

  if (dto <= 5) return 'high';

  return 'UNKNOWN';
};
