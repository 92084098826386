// React & Next
import { useEffect } from 'react';

// App - Types
import type { StoriesGraphVertex, StoriesGraphEdge } from '../types';

// App - Other
import { useReactFlow } from '@/components/molecules/charts/flow';

type NodesPropsSync = {
  isAttachingKnowledgeItem: boolean;
  didAttachKnowledgeItemErrored: boolean;
};

export const NodesPropsSync = ({
  isAttachingKnowledgeItem,
  didAttachKnowledgeItemErrored,
}: NodesPropsSync) => {
  const { setNodes } = useReactFlow<StoriesGraphVertex, StoriesGraphEdge>();

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        return {
          ...node,
          data: { ...node.data, isAttachingKnowledgeItem, didAttachKnowledgeItemErrored },
        };
      })
    );
  }, [setNodes, isAttachingKnowledgeItem, didAttachKnowledgeItemErrored]);

  return <></>;
};
