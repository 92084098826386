// 3rd
import { delay } from 'msw';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { AsyncTaskStatusDto } from '@/types/common/dtos/async-task';
import { AsyncTaskStatusesDto } from '@/types/common/dtos/async-task';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  status: z.enum(AsyncTaskStatusesDto).nullable().optional(),
  summary: z.string().nullable().optional(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getStorySummary = async (
  id: string
): Promise<{ status: AsyncTaskStatusDto | null; summary: string }> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({ securityStoryId: id });
    const res = await apiClient.post(`/SecurityStory/GetSummary`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return {
      status: parsedRes.status || null,
      summary: parsedRes.summary || '',
    };
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ###############
// Summary Pooling
// ###############

const getOrWatchStorySummary = async (storyId: string, signal: AbortSignal) => {
  try {
    let summary = await getStorySummary(storyId);

    while (
      !signal.aborted &&
      summary.status &&
      summary.status !== 'Completed' &&
      summary.status !== 'Failed'
    ) {
      await delay(10000);

      summary = await getStorySummary(storyId);
    }

    return summary.summary;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseStorySummary = {
  storyId?: string;
  enabled?: boolean;
};

export const useStorySummary = ({ storyId, enabled = true }: UseStorySummary) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: STORIES_QUERY_KEYS.summary(storyId!),
    queryFn: async ({ signal }) => await getOrWatchStorySummary(storyId!, signal),
    enabled: enabled && !!storyId,
  });

  return {
    summary: data,
    isFetchingSummary: isLoading || isFetching,
    didFetchingSummaryErrored: isLoadingError,
  };
};
