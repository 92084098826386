export const STORIES_QUERY_KEYS = {
  all: ['stories'] as const,
  stories: () => [...STORIES_QUERY_KEYS.all] as const,
  story: (id: string) => [...STORIES_QUERY_KEYS.stories(), { id }] as const,
  summary: (id: string) => [...STORIES_QUERY_KEYS.story(id), 'summary'] as const,
  securityAnalysis: (id: string) => [...STORIES_QUERY_KEYS.story(id), 'security_analysis'] as const,
  threatsAnalysis: (id: string) => [...STORIES_QUERY_KEYS.story(id), 'threats_analysis'] as const,
  requirementsAnalysis: (id: string) =>
    [...STORIES_QUERY_KEYS.story(id), 'requirements_analysis'] as const,
  coveredRequirementsAnalysis: (id: string) =>
    [...STORIES_QUERY_KEYS.story(id), 'covered_requirements_analysis'] as const,
  potentiallyRelatedKnowledgeItems: (id: string) =>
    [...STORIES_QUERY_KEYS.story(id), 'potentially_related_knowledge_items'] as const,
  contextGraph: (id: string) => [...STORIES_QUERY_KEYS.story(id), 'context_graph'] as const,
  storyEligibleSlackFeedbackRecipients: (id: string) =>
    [...STORIES_QUERY_KEYS.story(id), 'eligible_slack_feedback_recipients'] as const,
  relatedStoriesToKnowledgeItem: (collectionId: string, documentId: string) =>
    [
      ...STORIES_QUERY_KEYS.stories(),
      'related_to_knowledge_item',
      collectionId,
      documentId,
    ] as const,
};
