// 3rd
import { z } from 'zod';

// App - Types
import {
  CountermeasureSourcesDto,
  castCountermeasureSourceDtoToCountermeasureSource,
} from '@/types/security-framework/dtos/countermeasure-source';
import {
  CountermeasureSnippetTypesDto,
  castCountermeasureSnippetTypeDtoToCountermeasureSnippetType,
} from '@/types/security-framework/dtos/countermeasure-snippet-type';
import {
  AntiPatternTypesDto,
  castAntiPatternTypeDtoToAntiPatternType,
} from '@/types/security-framework/dtos/anti-pattern-type';
import { castRequirementPriorityScoreDtoToRequirementPriority } from '@/types/story/dtos/requirement-priority-score';
import type { Requirement } from '../../../types/requirements-analysis';

export const ZodAntiPatternDto = z.object({
  id: z.string(),
  content: z.string(),
  type: z.enum(AntiPatternTypesDto),
});

export type AntiPatternDto = z.infer<typeof ZodAntiPatternDto>;

export const ZodCountermeasureDto = z.object({
  id: z.string(),
  content: z.string(),
  title: z.string(),
  countermeasureSource: z.enum(CountermeasureSourcesDto),
  countermeasureSnippetType: z.enum(CountermeasureSnippetTypesDto),
  securityRequirementsIds: z.string().array(),
});

export type CountermeasureDto = z.infer<typeof ZodCountermeasureDto>;

export const ZodRequirementDto = z.object({
  background: z.string().optional(),
  categoryName: z.string().optional(),
  countermeasures: z.array(ZodCountermeasureDto).optional(),
  antiPatterns: z.array(ZodAntiPatternDto).optional(),
  createdAt: z.string(),
  explanation: z.string(),
  implementationExample: z.string(),
  priority: z.number(),
  requirementIdNumber: z.string(),
  securityControlName: z.string(),
  securityFrameworkName: z.string(),
  tailoredRequirement: z.string(),
  testCase: z.string(),
  isRelevant: z.boolean().optional(),
});

export type RequirementDto = z.infer<typeof ZodRequirementDto>;

export const castDtoToRequirement = (dto: RequirementDto): Requirement => {
  return {
    name: dto.requirementIdNumber,
    requirement: dto.tailoredRequirement,
    framework: dto.securityFrameworkName,
    control: dto.securityControlName,
    category: dto.categoryName || undefined,
    countermeasures: (dto.countermeasures || []).map((countermeasure) => ({
      content: countermeasure.content,
      source: castCountermeasureSourceDtoToCountermeasureSource(
        countermeasure.countermeasureSource
      ),
      snippetType: castCountermeasureSnippetTypeDtoToCountermeasureSnippetType(
        countermeasure.countermeasureSnippetType
      ),
      title: countermeasure.title,
    })),
    antiPatterns: (dto.antiPatterns || []).map((dto) => ({
      id: dto.id,
      content: dto.content,
      type: castAntiPatternTypeDtoToAntiPatternType(dto.type),
    })),
    implementationExample: dto.implementationExample,
    priority: castRequirementPriorityScoreDtoToRequirementPriority(dto.priority),
    priorityScore: dto.priority,
    explanation: dto.explanation,
    testCase: dto.testCase,
    background: dto.background || '',
    isRelevant: !!dto.isRelevant,
  };
};
