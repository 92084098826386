// 3rd
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { StoryPotentiallyRelatedKnowledgeItem } from '../types/story-related-knowledge-items';
import {
  castPotentiallyRelatedKnowledgeItemDtoToPotentiallyRelatedKnowledgeItemDto,
  ZodPotentiallyRelatedKnowledgeItemDto,
} from './dtos/potentially-related-knowledge-item';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  relatedKnowledgeItems: z
    .array(ZodPotentiallyRelatedKnowledgeItemDto)
    .default([])
    .catch((e) => {
      console.error('potentially related knowledge items parsing errored! see: ', e);

      return [];
    }),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getPotentiallyRelatedKnowledgeItems = async (
  storyId: string
): Promise<StoryPotentiallyRelatedKnowledgeItem[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({ securityStoryId: storyId });
    const res = await apiClient.post(`/SecurityStory/GetPotentiallyRelatedKnowledgeItems`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.relatedKnowledgeItems.map(
      castPotentiallyRelatedKnowledgeItemDtoToPotentiallyRelatedKnowledgeItemDto
    );
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UsePotentiallyRelatedKnowledgeItems = {
  storyId?: string;
  enabled?: boolean;
};

export const usePotentiallyRelatedKnowledgeItems = ({
  storyId,
  enabled = true,
}: UsePotentiallyRelatedKnowledgeItems) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: STORIES_QUERY_KEYS.potentiallyRelatedKnowledgeItems(storyId!),
    queryFn: async () => await getPotentiallyRelatedKnowledgeItems(storyId!),
    enabled: enabled && !!storyId,
  });

  return {
    potentiallyRelatedItems: data,
    isFetchingPotentiallyRelatedItems: isLoading || isFetching,
    didFetchingPotentiallyRelatedItemsErrored: isLoadingError,
  };
};
