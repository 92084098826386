// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateStory } from '../types/story';
import { castCreateStoryToDto, ZodCreateStoryDto } from './dtos/story';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodCreateStoryDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityStoryId: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const createStory = async (model: CreateStory): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(castCreateStoryToDto(model));
    const res = await apiClient.post(`/SecurityStory/CreateSecurityStory`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityStoryId;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateStory = {
  onCreateStart?: () => void;
  onCreateSuccess?: (newStoryId: string) => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateStory = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateStory = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateStory) => await createStory(model),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: STORIES_QUERY_KEYS.stories(),
      });
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: (res) => onCreateSuccess?.(res),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    createStory: mutate,
    isCreatingStory: isPending,
    didStoryCreationErrored: isError,
  };
};
