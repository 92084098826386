// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { ThreatSeverity } from '@/types/story/threat-severity';
import type { Story } from './story';

// App - Other
import { APP_URL } from '@/config/constants';
import { APPLICATIONS_ROUTES, REVIEWS_ROUTES } from '@/config/routes';

export type PotentiallyMissingInformation = {
  missingInformationPoint: string;
  reasoning: string;
  devQuestionnaire: string;
};

export type AddressedSecurityConsideration = {
  consideration: string;
  reasoning: string;
  threatMitigated: string;
};

export type RiskAreaAndAttackSurface = {
  explanation: string;
  riskArea: string;
};

export type RiskIdentification = {
  attackScenario: string;
  confidence: number;
  reasoning: string;
  risk: string;
};

export type BusinessLogicIssue = {
  issue: string;
  severityScore: number;
  severity: ThreatSeverity;
  reasoning: string;
  abuseScenario: string;
};

export const compareBusinessLogicIssues = (a: BusinessLogicIssue, b: BusinessLogicIssue) => {
  return a.issue === b.issue && a.severity === b.severity && a.reasoning === b.reasoning;
};

export type SecurityAnalysis = {
  addressedSecurityConsiderations: AddressedSecurityConsideration[];
  businessLogicIssues: BusinessLogicIssue[];
  riskAreasAndAttackSurfaces: RiskAreaAndAttackSurface[];
  riskIdentifications: RiskIdentification[];
  potentiallyMissingInformation: PotentiallyMissingInformation[];
  isOldResults: boolean;
};

export const translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage = (
  recipientName: string,
  potentiallyMissingInformation: PotentiallyMissingInformation[]
) => {
  if (!potentiallyMissingInformation.length) return '';

  let message = '';

  message += `Hi ${recipientName} 👋,`;
  message += '\n\n';
  message +=
    'The security team is starting to review your feature but still required additional information, please address the following:';

  potentiallyMissingInformation.forEach((info, index) => {
    message += `\n${index + 1}. ${info.devQuestionnaire}`;
  });

  message += '\n\n';
  message += 'You can reply to this message / comment, or simply edit your document 🙂🍀';
  message += '\n\n';
  message += 'Cheers,';
  message += '\n';
  message += 'Clover bot';

  return message;
};

const translatePotentiallyMissingInformationToDocumentFeedbackMap: Record<
  KnowledgeSource,
  (recipientName: string, potentiallyMissingInformation: PotentiallyMissingInformation[]) => string
> = {
  confluence: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  google_drive: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  jira: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  trello: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  linear: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  github: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  azure_devops: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  azure_devops_wiki_page: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  azure_devops_work_item: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  azure_devops_repository: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  one_drive: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  notion: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  gitlab: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
  UNKNOWN: translatePotentiallyMissingInformationToGenericDocumentFeedbackMessage,
};

export const translatePotentiallyMissingInformationToDocumentFeedbackMessage = (
  documentSource: KnowledgeSource,
  recipientName: string,
  potentiallyMissingInformation: PotentiallyMissingInformation[]
) => {
  if (!potentiallyMissingInformation.length) return '';

  return translatePotentiallyMissingInformationToDocumentFeedbackMap[documentSource](
    recipientName,
    potentiallyMissingInformation
  );
};

export const translatePotentiallyMissingInformationToSlackMessage = (
  recipientName: string,
  story: Story,
  storySeedKnowledgeItemTitle: string,
  storySeedKnowledgeItemLink: string,
  potentiallyMissingInformation: PotentiallyMissingInformation[]
) => {
  if (!potentiallyMissingInformation.length) return '';

  let message = '';

  message += `Hi ${recipientName} 👋,`;
  message += '\n\n\n';
  message += `The security team is starting to review your feature (${story.name}) but still required additional information, please address the following:`;
  message += '\n';

  potentiallyMissingInformation.forEach((info, index) => {
    message += `\n${index + 1}. ${info.devQuestionnaire}`;
  });

  message += '\n\n\n';
  message += 'You can reply to this message / comment, or simply edit your document 🙂🍀';
  message += '\n\n';
  message += `Link to Security Review in Clover: ${APP_URL}${story.applicationId ? APPLICATIONS_ROUTES.applicationReview(story.applicationId, story.id) : REVIEWS_ROUTES.review(story.id)}`;
  message += '\n';
  message += `Link to (${storySeedKnowledgeItemTitle}): ${storySeedKnowledgeItemLink}`;
  message += '\n\n';
  message += 'Cheers,';
  message += '\n';
  message += 'Clover bot';

  return message;
};
