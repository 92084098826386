// 3rd
import { Skeleton, forwardRef, Flex } from '@chakra-ui/react';
import type { ComponentWithAs, As } from '@chakra-ui/react';

// App - Types
import type { Requirement } from '../../types/requirements-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { Text } from '@/components/atoms/typography';
import { translatePriorityToColor } from '@/components/translators/knowledge-item/priority';
import { Insight } from '@/components/molecules/insight';
import { Tag } from '@/components/molecules/tag';

const locale = Locale.features.stories['stories-requirement-violation-insight'];

type StoriesRequirementViolationInsightComponent = ComponentWithAs<
  As,
  StoriesRequirementViolationInsightProps
> & { Loading: typeof Loading };

type StoriesRequirementViolationInsightProps = {
  requirement: Requirement;
  isPinned: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onDoubleClick: () => void;
};

export const StoriesRequirementViolationInsight = forwardRef(
  (
    {
      requirement,
      isPinned,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onDoubleClick,
    }: StoriesRequirementViolationInsightProps,
    ref
  ) => {
    return (
      <Insight
        title={
          <Flex direction="column" gap="xs">
            {!!requirement.antiPatterns && requirement.antiPatterns.length ? (
              <Tag w="fit-content">{locale['Anti pattern detected']}</Tag>
            ) : null}

            <Text
              variant="detail"
              display="inline-flex"
              alignItems="center"
              flexWrap="wrap"
              flex="1 0 0"
              py="xxs"
              px="none"
              color="text.secondary"
            >
              ({requirement.name}) {requirement.requirement}
            </Text>
          </Flex>
        }
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDoubleClick={onDoubleClick}
        ref={ref}
        styleOptions={{
          container: {
            border: '1px',
            borderColor: 'border.primary',
            borderRadius: 'sm',
            cursor: 'pointer',
          },

          header: {
            transition: 'all 150ms ease-in-out',
            bg: isPinned ? 'surface.secondary' : 'transparent',
            py: 'xs',

            _hover: {
              bg: 'surface.secondary',
            },
          },

          lineDecoration: {
            my: '2px',
            bg: translatePriorityToColor(requirement.priority),
          },
        }}
      />
    );
  }
) as StoriesRequirementViolationInsightComponent;

const Loading = () => {
  return <Skeleton h="42px" w="100%" />;
};

StoriesRequirementViolationInsight.Loading = Loading;
