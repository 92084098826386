// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { BusinessLogicIssue } from '../../../types/security-analysis';
import type { Threat } from '../../../types/threats-analysis';
import type { Requirement } from '../../../types/requirements-analysis';
import type { Story } from '../../../types/story';

// App - Other
import Locale from '@/locale/en.json';
import { APP_URL } from '@/config/constants';
import { APPLICATIONS_ROUTES, REVIEWS_ROUTES } from '@/config/routes';

const locale = Locale.features.stories.translators.feedback;

export const translateComponentsToParagraphFeedback = (
  source: KnowledgeSource,
  author: string,
  threats: Threat[],
  issues: BusinessLogicIssue[],
  requirements: Requirement[],
  story: Story
): [string, string[][], string] => {
  // let tableHeaders: string[] = [];
  let tableRows: string[][] = [];

  if (threats.length) {
    // if (issues.length) {
    //   tableHeaders.push(locale['Threat / Business logic issue']);
    // } else {
    //   tableHeaders.push(locale['Threat']);
    // }
    //
    // tableHeaders.push(locale['Explanation']);
    //
    // if (includeDescription) {
    //   tableHeaders.push(locale['What can you do about it?']);
    // }
    //
    // tableRows.push(tableHeaders);

    threats.forEach((t, index) => {
      const row = [];

      row.push(`${locale['Threat']} #${index + 1}: ${t.threat}`);
      row.push('-');
      row.push(t.reasoning || '');

      // if (includeDescription) {
      //   row.push(t.countermeasures);
      // }

      tableRows.push(row);
    });
  }

  if (issues.length) {
    // if (!tableHeaders.length) {
    //   tableHeaders.push(locale['Business logic issue']);
    //   tableHeaders.push(locale['Explanation']);
    //
    //   if (includeDescription) {
    //     tableHeaders.push(locale['What can you do about it?']);
    //   }
    //
    //   tableRows.push(tableHeaders);
    // }

    issues.forEach((is, index) => {
      const row = [];

      row.push(`${locale['Issue']} #${index + 1}: ${is.issue}`);
      row.push('-');
      row.push(is.reasoning || '');

      // if (includeDescription) {
      //   row.push(is.abuseScenario || '');
      // }

      tableRows.push(row);
    });
  }

  if (requirements.length) {
    // tableHeaders.push(locale['Security requirement']);
    // tableHeaders.push(locale['Explanation']);
    //
    // if (includeDescription) {
    //   tableHeaders.push(locale['What can you do about it?']);
    // }
    //
    // tableRows.push(tableHeaders);

    requirements.forEach((r, index) => {
      const row = [];

      row.push(`${locale['Requirement']} #${index + 1}: (${r.name}) ${r.requirement}`);
      row.push('-');
      row.push(r.explanation || '');

      // if (includeDescription) {
      //   if (r.countermeasures.length) {
      //     const content = r.countermeasures
      //       .map((c) => {
      //         return `*${c.title}*\n${c.content}`;
      //       })
      //       .join('\n');
      //
      //     row.push(content);
      //   } else if (r.implementationExample) {
      //     row.push(`${r.implementationExample}`);
      //   }
      // }

      tableRows.push(row);
    });
  }

  return [
    `${locale['The following threats & security issues were sent by']} @${author}, ${locale['using the Clover security design platform']}:\n`,
    tableRows,
    `\n${locale['See full report']}: ${APP_URL}${story.applicationId ? APPLICATIONS_ROUTES.applicationReview(story.applicationId, story.id) : REVIEWS_ROUTES.review(story.id)}`,
  ];
};
