// App - Types
import type { AntiPatternType } from '@/types/security-framework/anti-pattern-type';
import type { CountermeasureSource } from '@/types/security-framework/countermeasure-source';
import type { CountermeasureSnippetType } from '@/types/security-framework/countermeasure-snippet-type';
import type { RequirementPriority } from '@/types/story/requirement-priority';

export type CoveredRequirement = {
  framework: string;
  control: string;
  category?: string;
  name: string;
  requirement: string;
  reasoning: string;
};

export type CoveredRequirementsAnalysis = {
  coveredRequirements: CoveredRequirement[];
  isOldResults: boolean;
};

export const compareCoveredRequirements = (a: CoveredRequirement, b: CoveredRequirement) => {
  return (
    a.name === b.name &&
    a.requirement === b.requirement &&
    a.framework === b.framework &&
    a.control === b.control &&
    a.category === b.category
  );
};

export type AntiPattern = {
  id: string;
  content: string;
  type: AntiPatternType;
};

export type Countermeasure = {
  content: string;
  source: CountermeasureSource;
  snippetType: CountermeasureSnippetType;
  title: string;
};

export type Requirement = {
  framework: string;
  control: string;
  category?: string;
  requirement: string;
  background: string;
  name: string;
  countermeasures: Countermeasure[];
  antiPatterns: AntiPattern[];
  isRelevant: boolean;
  implementationExample: string;
  priority: RequirementPriority;
  priorityScore: number;
  explanation: string;
  testCase: string;
};

export type RequirementsAnalysis = {
  requirements: Requirement[];
  isOldResults: boolean;
};

export const compareRequirements = (a: Requirement, b: Requirement) => {
  return (
    a.name === b.name &&
    a.requirement === b.requirement &&
    a.framework === b.framework &&
    a.control === b.control &&
    a.category === b.category &&
    a.implementationExample === b.implementationExample &&
    a.priority === b.priority &&
    a.priorityScore === b.priorityScore &&
    a.explanation === b.explanation &&
    a.testCase === b.testCase &&
    a.background === b.background
  );
};
