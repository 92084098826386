// 3rd
import { Flex, Skeleton } from '@chakra-ui/react';

// App - Types
import { Position } from '@/components/molecules/charts/flow';

// App - Other
import { Handle } from './handle';

export const LoadingVertex = () => {
  return (
    <Flex direction="column" position="relative" maxW={`${VERTEX_MAX_WIDTH}px`}>
      <Handle type="target" position={Position.Top} />

      <Handle type="source" position={Position.Bottom} />

      <Handle type="target" position={Position.Left} />

      <Handle type="source" position={Position.Right} />

      <Skeleton w="160px" h="25px" />
    </Flex>
  );
};

export const VERTEX_MAX_WIDTH = 200;
export const EXTENSION_NAME = 'LoadingVertex';
export const EXTENSION = {
  LoadingVertex: LoadingVertex,
};
