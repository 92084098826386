// 3rd
import { z } from 'zod';

// App - Types
import { StatusesDto, castStatusDtoToStatus } from '@/types/story/dtos/status';
import { ZodIdDto, castIdDtoToId, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import type { CreateStory, Story } from '../../types/story';

// App - Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodStoryDto = z.object({
  id: z.string(),
  storyName: z.string(),
  status: z.enum(StatusesDto),
  creationTime: z.string(),
  lastModified: z.string(),
  seedItemId: ZodIdDto,
  relatedKnowledgeItemIds: z.array(ZodIdDto).default([]),
  application: z
    .object({
      id: z.string(),
      applicationName: z.string(),
    })
    .optional(),
});

export type StoryDto = z.infer<typeof ZodStoryDto>;

export const castDtoToStory = (dto: StoryDto): Story => {
  return {
    id: dto.id,
    name: dto.storyName,
    status: castStatusDtoToStatus(dto.status),
    creationTime: normalizeDatesToISOString(dto.creationTime),
    lastUpdated: normalizeDatesToISOString(dto.lastModified),
    seedItemId: castIdDtoToId(dto.seedItemId),
    relatedKnowledgeItemsIds: dto.relatedKnowledgeItemIds.map(castIdDtoToId),
    applicationId: dto.application?.id,
    applicationName: dto.application?.applicationName,
  };
};

export const ZodCreateStoryDto = z.object({
  applicationId: z.string().optional(),
  storyName: z.string(),
  seedItemId: ZodIdDto,
  relatedKnowledgeItemIds: z.array(ZodIdDto).default([]),
});

export type CreateStoryDto = z.infer<typeof ZodCreateStoryDto>;

export const castCreateStoryToDto = (payload: CreateStory): CreateStoryDto => {
  return {
    ...(payload.applicationId ? { applicationId: payload.applicationId } : {}),
    storyName: payload.name,
    seedItemId: castIdToIdDto(payload.seedItemId),
    relatedKnowledgeItemIds: payload.relatedKnowledgeItemsIds.map(castIdToIdDto),
  };
};
