// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import type { Id } from '@/types/knowledge-item/id';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  knowledgeItemId: ZodIdDto,
  ignoreReason: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// #######
// Request
// #######

export const ignoreSuggestedStory = async (knowledgeItemId: Id): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      knowledgeItemId: castIdToIdDto(knowledgeItemId),
      ignoreReason: 'MANUAL',
    });

    return await apiClient.post(`/SecurityStory/AddToIgnoreList`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseIgnoreSuggestedStory = {
  onSettled?: () => void;
  onStart?: (id: Id) => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useIgnoreSuggestedStory = ({
  onSettled,
  onStart,
  onSuccess,
  onError,
}: UseIgnoreSuggestedStory = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (id: Id) => await ignoreSuggestedStory(id),
    onSettled: () => onSettled?.(),
    onMutate: async (id: Id) => await onStart?.(id),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
  });

  return {
    ignoreSuggestion: mutate,
    isIgnoringSuggestion: isPending,
    didIgnoreSuggestionErrored: isError,
  };
};
