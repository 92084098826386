// App - Other
import { EXTENSION as SuggestedConnectionExtension } from './suggested-connection';
import { EXTENSION as LinkedConnectionExtension } from './linked-connection';

export { EXTENSION_NAME as SuggestedConnectionExtensionName } from './suggested-connection';
export { EXTENSION_NAME as LinkedConnectionExtensionName } from './linked-connection';

export const GRAPH_EDGES_OVERRIDES = {
  ...SuggestedConnectionExtension,
  ...LinkedConnectionExtension,
};
