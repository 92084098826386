// 3rd
import { Highlight, themes } from 'prism-react-renderer';
import { Flex, Code, SkeletonText, Divider } from '@chakra-ui/react';

// App - Types
import type { Requirement } from '../../types/requirements-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { useCopyToClipboard } from '@/components/hooks/use-copy-to-clipboard';
import { Text } from '@/components/atoms/typography';
import { Button } from '@/components/molecules/button';
import { Tag } from '@/components/molecules/tag';
import {
  translatePriority,
  translatePriorityToColor,
} from '@/components/translators/knowledge-item/priority';
// import { translateType } from '@/components/translators/security-framework/anti-pattern-type';

const locale = Locale.widgets.reviews['review-details'];

type StoriesRequirementViolationInformationProps = {
  requirement: Requirement;
};

export const StoriesRequirementViolationInformation = ({
  requirement,
}: StoriesRequirementViolationInformationProps) => {
  const [copiedValue, copy] = useCopyToClipboard();

  const cleanCodeSnippet = (snippet: string) => {
    if (snippet.startsWith('```')) {
      const firstNewlineIndex = snippet.indexOf('\n');

      if (firstNewlineIndex !== -1) {
        snippet = snippet.substring(firstNewlineIndex + 1);

        const lastBackticksIndex = snippet.lastIndexOf('```');

        if (lastBackticksIndex !== -1) {
          snippet = snippet.substring(0, lastBackticksIndex);
        }
      }
    }

    return snippet;
  };

  return (
    <Flex direction="column" gap="20px">
      <Flex direction="column">
        {requirement.category ? (
          <Text variant="detail" color="text.high-tone">
            {requirement.category}
          </Text>
        ) : null}

        <Text variant="label" color="text.high-tone">
          {requirement.control}
        </Text>
      </Flex>

      <Text variant="detail" color="text.high-tone">
        ({requirement.name}) {requirement.requirement}
      </Text>

      <Flex direction="column">
        <Flex alignItems="center" gap="24px">
          <Text variant="detail-semibold" color="text.high-tone">
            {locale['Impact']}
          </Text>

          <Tag size="old-md" bg={translatePriorityToColor(requirement.priority)}>
            <Text variant="detail-semibold" color="text.invert-primary">
              {translatePriority(requirement.priority)}
            </Text>
          </Tag>
        </Flex>
      </Flex>

      {requirement.explanation ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale["Why it's important?"]}
          </Text>

          <Text variant="detail" color="text.high-tone" w="95%">
            {requirement.explanation}
          </Text>
        </Flex>
      ) : null}

      {requirement.background && requirement.background.trim() ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['Requirement information']}
          </Text>

          <Text variant="detail" color="text.high-tone" w="95%">
            {requirement.background}
          </Text>
        </Flex>
      ) : null}

      {requirement.testCase ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['How to verify?']}
          </Text>

          <Text variant="detail" color="text.high-tone" w="95%">
            {requirement.testCase}
          </Text>
        </Flex>
      ) : null}

      {requirement.antiPatterns && requirement.antiPatterns.length ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['Detected anti patterns']}
          </Text>

          {requirement.antiPatterns.map((antiPattern, antiPatternIndex) => (
            <Flex key={antiPatternIndex} direction="column" gap="2px">
              <Flex alignItems="center" gap="sm">
                <Text variant="detail-semibold" color="text.high-tone">
                  {antiPatternIndex + 1}# {locale['Anti pattern']}
                </Text>

                {/*<Tag variant="secondary">{translateType(antiPattern.type)}</Tag>*/}
              </Flex>

              <Text variant="detail" color="text.high-tone" w="95%">
                {antiPattern.content}
              </Text>

              {antiPatternIndex !== requirement.antiPatterns.length - 1 ? (
                <Divider my="xs" />
              ) : null}
            </Flex>
          ))}
        </Flex>
      ) : null}

      {requirement.countermeasures.length ? (
        <Flex direction="column" gap="4px">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['Approved patterns']}
          </Text>

          {requirement.countermeasures.map((countermeasure, countermeasureIndex) => (
            <Flex key={countermeasureIndex} direction="column" gap="xs">
              <Text variant="detail-semibold" color="text.high-tone" w="95%">
                {countermeasure.title}
              </Text>

              <Highlight language="tsx" code={countermeasure.content} theme={themes.github}>
                {({ style, tokens, getLineProps, getTokenProps }) => (
                  <Code
                    padding={2}
                    rounded="sm"
                    py="sm"
                    px="md"
                    mt="sm"
                    display="block"
                    whiteSpace="pre"
                    backgroundColor={style.backgroundColor}
                    overflow="auto"
                    position="relative"
                    w="400px"
                    minW="400px"
                    maxW="400px"
                  >
                    <Button
                      position="absolute"
                      top="sm"
                      right="sm"
                      size="sm"
                      aria-label={locale['Copy']}
                      onClick={() => copy(countermeasure.content)}
                    >
                      {copiedValue === countermeasure.content ? locale['Copied'] : locale['Copy']}
                    </Button>

                    {tokens.map((line, i) => (
                      <div key={i} {...getLineProps({ line })}>
                        {line.map((token, key) => (
                          <span key={key} {...getTokenProps({ token })} />
                        ))}
                      </div>
                    ))}
                  </Code>
                )}
              </Highlight>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex direction="column" gap="xs">
          <Text variant="detail-semibold" color="text.high-tone" w="95%">
            {locale['Implementation example']}
          </Text>

          <Highlight
            language="tsx"
            code={cleanCodeSnippet(requirement.implementationExample)}
            theme={themes.github}
          >
            {({ style, tokens, getLineProps, getTokenProps }) => (
              <Code
                padding={2}
                rounded="sm"
                py="sm"
                px="md"
                mt="sm"
                display="block"
                whiteSpace="pre"
                backgroundColor={style.backgroundColor}
                overflow="auto"
                position="relative"
                w="400px"
                minW="400px"
                maxW="400px"
              >
                <Button
                  position="absolute"
                  top="sm"
                  right="sm"
                  size="sm"
                  aria-label={locale['Copy']}
                  onClick={() => copy(requirement.implementationExample)}
                >
                  {copiedValue === requirement.implementationExample
                    ? locale['Copied']
                    : locale['Copy']}
                </Button>

                {tokens.map((line, i) => (
                  <div key={i} {...getLineProps({ line })}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token })} />
                    ))}
                  </div>
                ))}
              </Code>
            )}
          </Highlight>
        </Flex>
      )}
    </Flex>
  );
};

const Loading = () => {
  return <SkeletonText w="100%" noOfLines={12} />;
};

StoriesRequirementViolationInformation.Loading = Loading;
