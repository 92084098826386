// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import type { Id } from '@/types/knowledge-item/id';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
  knowledgeItemDirectedEdges: z.array(
    z.object({
      sourceItemId: ZodIdDto,
      destinationItemId: ZodIdDto,
    })
  ),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// #######
// Request
// #######

export const attachKnowledgeItemToStory = async (
  storyId: string,
  edges: Array<{
    srcKnowledgeItemId: Id;
    destKnowledgeItemId: Id;
  }>
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityStoryId: storyId,
      knowledgeItemDirectedEdges: edges.map((edge) => ({
        sourceItemId: castIdToIdDto(edge.srcKnowledgeItemId),
        destinationItemId: castIdToIdDto(edge.destKnowledgeItemId),
      })),
    });

    return await apiClient.post(`/SecurityStory/AttachKnowledgeItemsEdges`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseAttachKnowledgeItemToStory = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useAttachKnowledgeItemToStory = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseAttachKnowledgeItemToStory = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({
      storyId,
      edges,
    }: {
      storyId: string;
      edges: Array<{
        srcKnowledgeItemId: Id;
        destKnowledgeItemId: Id;
      }>;
    }) => await attachKnowledgeItemToStory(storyId, edges),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: STORIES_QUERY_KEYS.story(variables.storyId!),
      });
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    attachKnowledgeItemToStory: mutate,
    isKnowledgeItemBeingAttachedToStory: isPending,
    didAttachKnowledgeItemToStoryErrored: isError,
  };
};
