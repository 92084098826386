// React & Next
import { useMemo } from 'react';

// App - Types
import type { Edge, Node } from '@/components/molecules/charts/flow';

// App - Other
import { EmptyVertexExtensionName } from '../vertices';

export const usePrepareEmptyGraph = (): {
  nodes: Node[];
  edges: Edge[];
} => {
  const nodes: Node[] = useMemo(
    () => [
      {
        id: `empty-node`,
        type: EmptyVertexExtensionName,
        position: { x: 0, y: 0 },
        data: {},
      },
    ],
    []
  );

  return {
    nodes,
    edges: [],
  };
};
