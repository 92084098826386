// 3rd
import { delay } from 'msw';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { AsyncTaskStatusDto } from '@/types/common/dtos/async-task';
import { AsyncTaskStatusesDto } from '@/types/common/dtos/async-task';
import type { Threat } from '../types/threats-analysis';
import { castDtoToThreat, ZodThreatDto } from './dtos/threats-analysis';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { STORIES_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityStoryId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  status: z.enum(AsyncTaskStatusesDto).nullable().optional(),
  data: z.array(ZodThreatDto).optional().nullable(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

const getThreatsAnalysis = async (
  storyId: string
): Promise<{ status: AsyncTaskStatusDto | null; assessment: Threat[] | null }> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({ securityStoryId: storyId });
    const res = await apiClient.post(`/SecurityStory/GetThreats`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return {
      status: parsedRes.status || null,
      assessment: parsedRes.data ? parsedRes.data.map(castDtoToThreat) : null,
    };
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ################
// Analysis Pooling
// ################

const getOrWatchThreatsAnalysis = async (
  storyId: string,
  signal: AbortSignal
): Promise<Threat[] | null> => {
  try {
    let analysis = await getThreatsAnalysis(storyId);

    while (
      !signal.aborted &&
      analysis.status &&
      analysis.status !== 'Completed' &&
      analysis.status !== 'Failed'
    ) {
      await delay(10000);

      analysis = await getThreatsAnalysis(storyId);
    }

    return analysis.assessment;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseThreatsAnalysis = {
  storyId?: string;
  enabled?: boolean;
};

export const useThreatsAnalysis = ({ storyId, enabled = true }: UseThreatsAnalysis = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: STORIES_QUERY_KEYS.threatsAnalysis(storyId!),
    queryFn: async ({ signal }) => await getOrWatchThreatsAnalysis(storyId!, signal),
    enabled: enabled && !!storyId,
  });

  return {
    threats: data,
    isFetchingThreatsAnalysis: isLoading || isFetching,
    didFetchingThreatsAnalysisErrored: isLoadingError,
  };
};
