// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { BusinessLogicIssue } from '../../../types/security-analysis';
import type { Threat } from '../../../types/threats-analysis';
import type { Requirement } from '../../../types/requirements-analysis';

// App - Other
import Locale from '@/locale/en.json';
import { APP_URL } from '@/config/constants';

const locale = Locale.features.stories.translators.feedback;

export const prepareAzureDevOpsWikiPageFeedbackContent = (
  recipient: { source: KnowledgeSource; author: string },
  threats: Threat[],
  issues: BusinessLogicIssue[],
  requirements: Requirement[],
  href: string,
  includeDescription: boolean,
  author: string,
  includeActionsInFeedback: boolean
) => {
  let content = '';

  if (threats.length && issues.length) {
    content += `## ${locale['Security threats & issues by Clover']} 🍀`;
  } else if (threats.length) {
    content += `## ${locale['Security threats by Clover']} 🍀`;
  } else if (issues.length) {
    content += `## ${locale['Business logic issues by Clover']} 🍀`;
  } else if (requirements.length) {
    content += `## ${locale['Security requirements by Clover']} 🍀`;
  }

  content += '\n\n';
  content += `${locale['The following security findings were sent by']} @${author} ${locale['using Clover Secure Design Platform.']}`;
  content += '\n\n';
  content += `${locale['View full review']} [here](${APP_URL}${href}).`;
  content += '\n\n';

  if (threats.length) {
    content +=
      `| **${locale['Threat']}** | **${locale['Explanation']}** ` +
      (includeDescription ? `| **${locale['What can you do about it?']}** ` : '') +
      `| **${locale['Actions']}** |\n`;
    content +=
      '| --- ' +
      (includeActionsInFeedback ? '| --- ' : '') +
      (includeDescription ? '| --- ' : '') +
      '| --- |\n';

    threats.forEach((t) => {
      content += `| **${t.threat}** | ${t.reasoning || ''} `;

      if (includeDescription) {
        content += `| ${t.countermeasures.replaceAll('\n', ' ').replaceAll('- ', '🔹 ') || ''} `;
      }

      if (includeActionsInFeedback) {
        content += `| `;
        content += `[👍 ${locale['Mark as WIP']}](${APP_URL}${href})`;
        content += '  /  ';
        content += `[👎 ${locale['Reject']}](${APP_URL}${href})`;
        content += '  /  ';
        content += `[🤔 ${locale['See more details']}](${APP_URL}${href})`;
        content += ` |`;
      }

      content += '\n';
    });

    content += '\n';
  }

  if (issues.length) {
    if (threats.length) {
      content += '\n\n';
    }

    content +=
      `| **${locale['Business logic issue']}** | **${locale['Explanation']}** ` +
      (includeDescription ? `| **${locale['What can you do about it?']}** ` : '') +
      `| **${locale['Actions']}** |\n`;
    content +=
      '| --- ' +
      (includeActionsInFeedback ? '| --- ' : '') +
      (includeDescription ? '| --- ' : '') +
      '| --- |\n';

    issues.forEach((i) => {
      content += `| **${i.issue}** | ${i.reasoning || ''} `;

      if (includeDescription) {
        content += `| ${i.abuseScenario.replaceAll('\n', ' ').replaceAll('- ', '🔹 ') || ''} `;
      }

      if (includeActionsInFeedback) {
        content += `| `;
        content += `[👍 ${locale['Mark as WIP']}](${APP_URL}${href})`;
        content += '  /  ';
        content += `[👎 ${locale['Reject']}](${APP_URL}${href})`;
        content += '  /  ';
        content += `[🤔 ${locale['See more details']}](${APP_URL}${href})`;
        content += ` |`;
      }

      content += '\n';
    });

    content += '\n';
  }

  if (requirements.length) {
    content +=
      `| **${locale['Security requirement']}** | **${locale['Explanation']}** ` +
      (includeDescription ? `| **${locale['What can you do about it?']}** ` : '') +
      `| **${locale['Actions']}** |\n`;
    content +=
      '| --- ' +
      (includeActionsInFeedback ? '| --- ' : '') +
      (includeDescription ? '| --- ' : '') +
      '| --- |\n';

    requirements.forEach((r) => {
      content += `| **(${r.name}) ${r.requirement}** | ${r.explanation || ''} `;

      if (includeDescription) {
        if (r.countermeasures.length) {
          content += '| ';

          r.countermeasures.forEach((cm) => {
            content += `**${cm.title}** - ${cm.content}`;

            // if (r.countermeasures.length - 1 !== index) {
            //   content += '\n';
            // }
          });
        } else if (r.implementationExample) {
          content += '| ';
          content += '```';
          content += `${r.implementationExample.replaceAll('\n', '')}`;
          content += '```';
        }
      }

      if (includeActionsInFeedback) {
        content += `| `;
        content += `[👍 ${locale['Mark as WIP']}](${APP_URL}${href})`;
        content += '  /  ';
        content += `[👎 ${locale['Reject']}](${APP_URL}${href})`;
        content += '  /  ';
        content += `[🤔 ${locale['See more details']}](${APP_URL}${href})`;
        content += ` |`;
      }

      content += '\n';
    });

    content += '\n';
  }

  return content;
};
