// App - Types
import type { HandleProps as BaseHandleProps } from '@/components/molecules/charts/flow';

// App - Other
import { Handle as BaseHandle } from '@/components/molecules/charts/flow';

type HandleProps = BaseHandleProps;

export const Handle = ({ ...handleProps }: HandleProps) => {
  return (
    <BaseHandle
      style={{
        background: 'transparent',
        border: '1px solid',
        borderColor: 'transparent',
      }}
      {...handleProps}
    />
  );
};
